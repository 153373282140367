import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Col,
  Row,
  PageHeader,
  Button,
  List,
  Card,
  Popconfirm,
  Statistic,
  Collapse,
  Space,
  Spin,
  Input
} from 'antd';
import moment from 'moment';
import {
  CalendarOutlined,
  DeleteOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import RatesList from './ratesList';
import ModulesRatesTable from './modulesRatesTable';
import Planning from '../SessionPlanning/Planning';
import ModalAddLocation from './ModalAddLocation';
import { MapIcon } from '../../../utils/constants/customIcon';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import calculateRateModule from './utils/calculateRateModule';

const { Panel } = Collapse;

// ID de la session
const ModulesList = ({
  contributors,
  id,
  refresh,
  setRefresh,
  customers,
  modules
}) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [module, setModule] = useState({});
  const [ratesDelete, setRatesDelete] = useState(false);
  const [reloadCalendar, setReloadCalendar] = useState(false);
  const [purpose, setPurpose] = useState('');
  const { notification } = ErrorStatusCode();
  const [rename, setRename] = React.useState('');

  const createModule = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/module', body });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const addModule = async () => {
    setIsFieldsLoading(true);
    await createModule({ session: id, total_rates: 0 });
    setRefresh(!refresh);
    setIsFieldsLoading(false);
  };

  const deleteModule = async (idModule) => {
    try {
      setIsFieldsLoading(true);
      await dispatchAPI('DELETE', { url: `/module/${idModule}` });
      setRefresh(!refresh);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const renameModule = async (idModule) => {
    try {
      setIsFieldsLoading(true);
      await dispatchAPI('PATCH', {
        url: `/module/${idModule}`,
        body: {
          name: rename
        }
      });
      setRefresh(!refresh);
      setIsFieldsLoading(false);
      setRename('');
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const onChange = () => {
    setEdit(false);
  };
  const nbHours = (mod) => {
    let hoursTot = 0;
    mod.slots.date.forEach((date) => {
      date.sub_slots.forEach((subSlot) => {
        const endTime = moment(subSlot.end_time, 'HH:mm');
        const startTime = moment(subSlot.start_time, 'HH:mm');
        hoursTot += (endTime - startTime) / 3600000;
      });
    });
    return hoursTot;
  };
  return (
    <div>
      <Spin size="large" spinning={isFieldsLoading}>
        <List
          dataSource={modules}
          renderItem={(item) => (
            <List.Item>
              <Card
                className="session-module-card"
                style={{ width: '100%' }}
                title={item.name}
                extra={
                  <>
                    <Popconfirm
                      title={
                        <>
                          {t('datatable.column.action.rename.title')}
                          <br />
                          <Input
                            value={rename}
                            onChange={(event) => setRename(event.target.value)}
                            defaultValue={item.name}
                            type="text"
                            placeholder={t(
                              'datatable.column.action.rename.placeholder'
                            )}
                          />
                        </>
                      }
                      okText={t('datatable.column.action.rename.ok')}
                      okButtonProps={{ danger: true }}
                      cancelText={t('datatable.column.action.rename.cancel')}
                      onConfirm={() => {
                        renameModule(item._id);
                      }}
                      icon={<EditOutlined />}
                    >
                      <Button type="link" danger>
                        {t('buttons.rename')}
                        <EditOutlined />
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ danger: true }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() => {
                        deleteModule(item._id);
                      }}
                      icon={<WarningOutlined />}
                    >
                      <Button type="link" danger>
                        {`${t('buttons.delete')} `}
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </>
                }
              >
                <PageHeader title={t('session.module.place')} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <MapIcon />
                  </div>
                  {item.training_place ? (
                    <>
                      {item.training_place.distance ? (
                        <h2 style={{ margin: '0 0 0 16px', fontSize: 16 }}>
                          Formation a distance
                        </h2>
                      ) : item.training_place.training_place ? (
                        <h2 style={{ margin: '0 0 0 16px', fontSize: 16 }}>
                          {item.training_place.training_place.name}
                        </h2>
                      ) : (
                        <h2 style={{ margin: '0 0 0 16px', fontSize: 16 }}>
                          {item.training_place.location}
                        </h2>
                      )}
                      <Button
                        style={{ marginLeft: 16 }}
                        type="primary"
                        onClick={() => {
                          setPurpose('Modification');
                          setModule(item);
                          setVisible(true);
                        }}
                      >
                        Modifier le lieu de formation
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ marginLeft: 16 }}
                      type="primary"
                      onClick={() => {
                        setPurpose('Ajout');
                        setModule(item);
                        setVisible(true);
                      }}
                    >
                      Ajouter un lieu de formation
                    </Button>
                  )}
                </div>
                <PageHeader title={t(`sessions.form.rate`)} />
                <Collapse
                  accordion
                  onChange={onChange}
                  className="session-module-rate-collapse"
                >
                  <Panel
                    header={
                      item.rates && item.rates.length ? (
                        item.total_rates_company &&
                        item.total_rates_company > 0 ? (
                          <Row>
                            <Col span={8}>
                              <Statistic
                                title={t('sessions.form.company_rate')}
                                suffix="€"
                                value={parseFloat(
                                  item.total_rates_company
                                ).toFixed(2)}
                              />
                            </Col>
                            <Col span={8}>
                              <Statistic
                                title={t('sessions.form.independent_rate')}
                                suffix="€"
                                value={parseFloat(
                                  item.total_rates_independent
                                ).toFixed(2)}
                              />
                            </Col>
                            <Col span={8}>
                              <Statistic
                                title={t('sessions.form.private_rate')}
                                suffix="€"
                                value={parseFloat(
                                  item.total_rates_private
                                ).toFixed(2)}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Statistic
                            suffix="€"
                            value={parseFloat(
                              calculateRateModule(item, customers)
                            ).toFixed(2)}
                          />
                        )
                      ) : (
                        <Button type="primary">
                          {t('sessions.form.button.addRate')}
                        </Button>
                      )
                    }
                    key="1"
                  >
                    {item.rates.length ? (
                      <>
                        <ModulesRatesTable
                          mod={item}
                          forceRefresh={forceRefresh}
                          setForceRefresh={setForceRefresh}
                          edit={edit}
                          setEdit={setEdit}
                          ratesDelete={ratesDelete}
                          setRatesDelete={setRatesDelete}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                        {!edit && (
                          <Space>
                            <Button
                              style={{ marginTop: 16 }}
                              type="primary"
                              onClick={() => {
                                setEdit(!edit);
                              }}
                            >
                              {t('sessions.form.button.modifyRate')}
                            </Button>
                          </Space>
                        )}
                      </>
                    ) : (
                      <RatesList
                        setForceRefresh={setForceRefresh}
                        forceRefresh={forceRefresh}
                        mod={item}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    )}
                  </Panel>
                </Collapse>

                <PageHeader title={t(`sessions.form.date`)} />
                <Collapse accordion>
                  <Panel
                    header={
                      item.slots?.date.length ? (
                        <>
                          <Statistic
                            prefix={<CalendarOutlined />}
                            value={
                              item.slots.start_date
                                ? `Du ${moment(item.slots.start_date).format(
                                    'DD-MM-YYYY'
                                  )} au ${moment(item.slots.end_date).format(
                                    'DD-MM-YYYY'
                                  )}`
                                : 'Aucune date'
                            }
                          />
                          <Statistic
                            value={`${
                              item.slots.date.filter(
                                (date) => date.sub_slots.length
                              ).length
                            } dates (${nbHours(item)} heures)`}
                          />
                        </>
                      ) : (
                        <Button type="primary">
                          {t('sessions.form.button.addDates')}
                        </Button>
                      )
                    }
                  >
                    <Planning
                      contributors={contributors}
                      module={item}
                      reloadCalendar={reloadCalendar}
                      setReloadCalendar={setRefresh}
                    />
                  </Panel>
                </Collapse>
              </Card>
            </List.Item>
          )}
        />
        {/* {isFieldsLoading.add && (
        <Spin size="large">
          <div
            style={{
              margin: 10,
              height: 100,
              width: '100%',
            }}
          />
        </Spin>
      )} */}
        <Button
          style={{
            float: 'right',
            margin: 20
          }}
          onClick={addModule}
          type="primary"
        >
          {t('sessions.form.button.addModule')}
        </Button>
        <ModalAddLocation
          isModalVisible={visible}
          setIsModalVisible={setVisible}
          module={module}
          purpose={purpose}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          setRefresh={setRefresh}
        />
      </Spin>
    </div>
  );
};

ModulesList.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  modules: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ModulesList;
