import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Button, Form, message, Row, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useFields from './ContributorsFields';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components';
import SlotsToContributorsSession from './SlotsToContributorsSession';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const SessionsAddContributors = ({ purpose }) => {
  const [status, setStatus] = useState('');
  const { fields } = useFields(status, setStatus);
  const history = useHistory();
  const loc = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [session, setSession] = useState({});
  const [modules, setModules] = useState([]);
  const [slotsData, setSlotsData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [slotsContributor, setSlotsContributor] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [contributor, setContributor] = useState({});
  const splitURL = loc.pathname.split('/');
  const idSession = splitURL[3];
  const idContributor = splitURL[6];
  const [form] = Form.useForm();
  const contributors = form.getFieldsValue(['contributors']).contributors;
  const { notification } = ErrorStatusCode();

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${idSession}`
      });
      setSession(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getContributor = async (idContrib) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contributors/${idContrib}`
      });
      setContributor(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/module?populate=slots.date.sub_slots.contributors'
      });
      setModules(
        data.filter(
          (el) =>
            el.session.toString() === idSession.toString() &&
            el.slots.date.length
        )
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await getModules();
    })();
  }, []);

  useEffect(() => {
    const treeData = [];
    modules.forEach((module) => {
      treeData.push({
        title: module.name,
        id: module._id,
        key: module._id,
        children: []
      });
    });
    modules.forEach((module) => {
      treeData.forEach((item, index) => {
        if (module._id === item.key) {
          module.slots.date
            .sort((a, b) => {
              return new Date(a.date) - new Date(b.date);
            })
            .forEach((date) => {
              treeData[index].children.push({
                title: `${moment(date.date).format('dddd')} ${moment(
                  date.date
                ).format('LL')}`,
                key: date._id,
                children: []
              });
            });
        }
      });
    });
    modules.forEach((module) => {
      module.slots.date.forEach((date) => {
        treeData.forEach((item, index) => {
          item.children.forEach((el, indexChild) => {
            if (el.key === date._id) {
              date.sub_slots.forEach((subSlot) => {
                treeData[index].children[indexChild].children.push({
                  title: `${subSlot.start_time} - ${subSlot.end_time}`,
                  contributors: subSlot.contributors,
                  key: subSlot._id
                });
              });
            }
          });
        });
      });
    });
    setSlotsData(treeData);
  }, [modules]);

  useEffect(() => {
    const data = [];
    modules.forEach((module) => {
      module.slots.date.forEach((date) => {
        date.sub_slots.map((subSlot) =>
          data.push({
            title: `${subSlot.start_time} - ${subSlot.end_time}`,
            key: subSlot._id
          })
        );
      });
    });
  }, [modules]);

  const onChange = async (_, values) => {
    if (
      values.contributors &&
      values.contributors !== contributor.contributor &&
      values.contributors !== contributor._id
    ) {
      await getContributor(values.contributors);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      if (session) {
        const contrib =
          session &&
          session.contributors &&
          session.contributors.find((el) => el.contributor === idContributor);
        setContributor(contrib);
      }
    }
  }, [session]);

  useEffect(() => {
    if (contributor) {
      setStatus(contributor.status);
    }
  }, [contributor]);

  useEffect(() => {
    form.setFieldsValue({
      ...contributor,
      contributors: contributor
        ? contributor.contributor
          ? contributor.contributor
          : contributor._id
        : null
    });
  }, [contributor]);

  const updateSession = async (newBody) => {
    try {
      if (purpose === 'create') {
        const body = { contributors: [...session.contributors, newBody] };
        await dispatchAPI('PATCH', { url: `/sessions/${idSession}`, body });
        message.success(t('sessions.form.success.createContributor'));
      }
      if (purpose === 'edit') {
        const body = newBody;
        await dispatchAPI('PATCH', { url: `/sessions/${idSession}`, body });
        message.success(t('sessions.form.success.editContributor'));
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const slotsContributorEdit = () => {
    let id = '';
    const list = [];
    const finalList = [];

    selectedModules.forEach((module) => {
      const tmpSlots = module.slots?.date;
      tmpSlots &&
        tmpSlots.forEach((date, indexDate) => {
          date.sub_slots.forEach((subSlot, indexSub) => {
            tmpSlots[indexDate].sub_slots[
              indexSub
            ].contributors = subSlot.contributors.filter(
              (el) => el !== contributors
            );
          });
        });

      tmpSlots &&
        tmpSlots.forEach((date, indexDate) => {
          tmpSlots[indexDate].module = module;
          date.sub_slots.forEach((subSlot, indexSub) => {
            if (!checkedKeys.length) {
              const filteredContrib = subSlot.contributors.filter(
                (c) => c._id !== idContributor
              );
              tmpSlots[indexDate].sub_slots[indexSub] = {
                type: subSlot.type,
                start_time: subSlot.start_time,
                end_time: subSlot.end_time,
                contributors: filteredContrib
              };
            } else {
              checkedKeys.forEach((key) => {
                if (key === subSlot._id) {
                  tmpSlots[indexDate].sub_slots[indexSub] = {
                    type: subSlot.type,
                    start_time: subSlot.start_time,
                    end_time: subSlot.end_time,
                    contributors: subSlot.contributors
                      .map((el) => el._id)
                      .includes(contributors)
                      ? [...subSlot.contributors]
                      : [...subSlot.contributors, contributors]
                  };
                } else if (!checkedKeys.includes(subSlot._id)) {
                  tmpSlots[indexDate].sub_slots[
                    indexSub
                  ].contributors = subSlot.contributors.filter(
                    (el) => el._id !== contributors
                  );
                }
              });
            }
          });
        });
      list.push(...tmpSlots);
    });
    list.forEach((slot) => {
      if (id !== slot.module._id) {
        id = slot.module._id;
        const slots = list
          .filter((el) => el.module._id === id)
          .map((el) => ({ ...el, module: el.module._id }));
        finalList.push({
          module: slot.module,
          slots
        });
      }
    });
    return finalList;
  };

  const updateModule = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/module/${id}`, body });
    } catch (e) {
      console.log('e', e);
      if (e.response) message.error(e.response.status);
    }
  };

  const calculateDurations = (values) => {
    let training_days = 0;
    let training_hours = 0;
    const modulesList = slotsContributorEdit();

    modulesList.forEach((module) => {
      module.slots.forEach((st) => {
        const sub = st.sub_slots.filter((ss) => {
          if (
            ss.contributors &&
            ss.contributors
              .map((contrib) => (contrib._id ? contrib._id : contrib))
              .includes(values.contributors)
          ) {
            const endTime = moment(ss.end_time, 'HH:mm');
            const startTime = moment(ss.start_time, 'HH:mm');
            training_hours += (endTime - startTime) / 3600000;
            return true;
          }
          return false;
        });
        if (sub.length > 0) {
          training_days += 1;
        }
      });
    });
    return { training_days, training_hours, modulesList };
  };

  const addContributorToSessions = async (values) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contributors/${values.contributors}`
      });
      const rate = values.rate || data.rate || 0;
      const tva =
        (values.tva && parseFloat(values.tva)) ||
        (data.tva && parseFloat(data.tva)) ||
        0.0;
      const duration = values.duration || data.duration;
      const durations = calculateDurations(values);
      await updateSession({
        contributor: values.contributors,
        training_hours: durations.training_hours,
        training_days: durations.training_days,
        status: values.status,
        rate,
        duration,
        tva
      });
      if (checkedKeys.length) {
        updateSlotsModule(durations.modulesList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateSlotsModule = (finalList) => {
    finalList.forEach((el) => {
      (async () => {
        await updateModule(el.module._id, {
          slots: {
            ...el.module.slots,
            date: el.slots
          }
        });
      })();
    });
  };

  const updateContributorSessions = async (values) => {
    const oldContributorsList = session.contributors;
    const contributorsList = oldContributorsList.filter(
      (el) => el.contributor !== idContributor
    );
    const durations = calculateDurations(values);
    const newBody = {
      contributors: [
        ...contributorsList,
        {
          contributor: values.contributors,
          training_hours: durations.training_hours,
          training_days: durations.training_days,
          status: values.status,
          rate: values.rate,
          duration: values.duration,
          tva: values.tva
        }
      ]
    };
    await updateSession(newBody);
    updateSlotsModule(durations.modulesList);
  };

  const handleSubmit = async (values) => {
    if (purpose === 'create') await addContributorToSessions(values);
    if (purpose === 'edit') await updateContributorSessions(values);
    history.goBack();
  };

  return (
    <>
      <PageHeaderCustom title={t(`sessions.form.addContributors`)} />
      <ContentCustom>
        <>
          <Form
            onValuesChange={onChange}
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
          >
            {fields.map((field) =>
              generateFields('sessions' || 'sessions', field)
            )}
            <Collapse
              accordion
              ghost
              className="session-contributor-slots-collapse"
              defaultActiveKey={['1']}
            >
              <Panel header="Liste des créneaux" key="1">
                <ContentCustom>
                  {slotsData.length ? (
                    <SlotsToContributorsSession
                      slots={slotsData}
                      modules={modules}
                      form={form}
                      expandedKeys={expandedKeys}
                      checkedKeys={checkedKeys}
                      selectedKeys={selectedKeys}
                      setCheckedKeys={setCheckedKeys}
                      setExpandedKeys={setExpandedKeys}
                      setSelectedKeys={setSelectedKeys}
                      idContributor={idContributor}
                      slotsContributor={slotsContributor}
                      setSlotsContributor={setSlotsContributor}
                      selectedModules={selectedModules}
                      setSelectedModules={setSelectedModules}
                    />
                  ) : (
                    <Spin size="large" spinning />
                  )}
                </ContentCustom>
              </Panel>
            </Collapse>
            <Row style={{ padding: '10px 0 24px 0', float: 'right' }}>
              <Button onClick={() => history.goBack()} type="link" danger>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit">
                {t('buttons.save')}
                <CheckOutlined />
              </Button>
            </Row>
          </Form>
        </>
      </ContentCustom>
    </>
  );
};

export default SessionsAddContributors;
