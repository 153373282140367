import ErrorStatusCode from '../../../../utils/ErrorStatusCode';

/**
 * Custom hook for managing program contributor data.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Object} options.progContribData - The program contributor data.
 * @param {Function} options.setProgContribData - The function to set the program contributor data.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {string} options.id - The ID of the session.
 * @returns {Object} - An object containing functions to fetch program contributor data.
 */
export const useProgContrib = ({
  progContribData,
  setProgContribData,
  dispatchAPI,
  id,
  searchValue
}) => {
  const { notification } = ErrorStatusCode();

  const getEmails = async (internal) => {
    if (!internal) {
      setProgContribData({ ...progContribData, isLoading: true });
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails?session=${id}&populate=user`
      });
      if (internal) {
        return { emails: data };
      }
      return setProgContribData({
        ...progContribData,
        emails: data,
        isLoading: false
      });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
      throw e;
    }
  };

  const fetchSession = async (internal) => {
    if (!internal) {
      setProgContribData({ ...progContribData, isLoading: true });
    }

    const { data } = await dispatchAPI('GET', {
      url: `/sessions/${id}?customer=${JSON.stringify([
        { path: 'program' },
        { path: 'contributors', populate: { path: 'contributor' } },
        { path: 'files', populate: { path: 'user' } }
      ])}`
    });
    if (internal) {
      return { session: data };
    }
    return setProgContribData({
      ...progContribData,
      session: data,
      isLoading: false
    });
  };

  const getModules = async (internal) => {
    if (!internal) {
      setProgContribData({ ...progContribData, isLoading: true });
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/module?session=${id}`
      });
      if (internal) {
        return { modules: data };
      }
      return setProgContribData({
        ...progContribData,
        modules: data,
        isLoading: false
      });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
      throw e;
    }
  };

  const getPrograms = async (internal) => {
    if (!internal) {
      setProgContribData({ ...progContribData, isLoading: true });
    }

    try {
      const fields = ['name'];
      const fieldsString = fields.join(' ');
      const { data } = await dispatchAPI('GET', {
        url: `/programs?fields=${fieldsString}${
          searchValue ? `&filter={"$text": {"$search":"${searchValue}"}}` : ''
        }`
      });
      const finaldata = data.map(({ _id, ...program }) => ({
        ...program,
        key: _id
      }));
      if (internal) {
        return { programs: finaldata };
      }
      return setProgContribData({
        ...progContribData,
        programs: finaldata,
        isLoading: false
      });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
      throw e;
    }
  };

  const fetchProgContribData = async () => {
    setProgContribData({ ...progContribData, isLoading: true });
    try {
      const [
        emailsData,
        sessionData,
        modulesData,
        programsData
      ] = await Promise.all([
        getEmails(true),
        fetchSession(true),
        getModules(true),
        getPrograms(true)
      ]);
      setProgContribData({
        ...progContribData,
        ...emailsData,
        ...sessionData,
        ...modulesData,
        ...programsData,
        isLoading: false
      });
    } catch (e) {
      if (e.response) notification(e.response);
      setProgContribData({ ...progContribData, isLoading: false });
    }
  };

  return {
    fetchProgContribData,
    getEmails,
    fetchSession,
    getModules,
    getPrograms
  };
};
