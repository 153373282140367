// I commented all the code to add a column to the kanban
// following Diane's demand.
// Do not delete this code in case of it will be used later.
import React, { useEffect, useState } from 'react';
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Alert, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useBoxContext from './SessionsContext';
import SessionKanbanColumns from './SessionKanbanColumns';
import { routes } from '../../../utils/constants/routes';
import KanbanBackground from '../../../assets/images/kanban-background_andrew-neel.png';
import useAuthContext from '../../../contexts/AuthContext';
// import { AddColIcon } from '../../../utils/constants/customIcon';
import CheckTrialDays from '../../../utils/checkTrialDays';

const KanbanWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  position: relative;
  height: calc(100vh - 250px);
  margin: 0 0 -18px -4px;
  background: url(${KanbanBackground}) no-repeat;
  background-size: cover;
  background-position-y: 60%;
`;

const { Search } = Input;
const { Option } = Select;

const SessionsKanbanBoard = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const history = useHistory();
  const [searchType, setSearchType] = useState('session');
  const {
    searchResource,
    searchValue,
    searchTypeValue,
    fetchKanbanColumns,
    kanbanCols,
    fetchData
  } = useBoxContext();

  const [newKanbanCol, setNewKanbanCol] = useState({
    label: 'Nouvelle colonne',
    icon: null,
    type: 'sessions',
    show: true,
    color: '#E4EAEB',
    index: ''
  });

  const kanbanLabels = [
    'A VENIR',
    'A PLANIFIER',
    'EN COURS',
    'TERMINEE',
    'CLOTUREE'
  ];

  const sortedKanbanCols = kanbanCols
    .filter((col) => {
      return kanbanLabels.includes(col.label);
    })
    .sort((a, b) => {
      return b.index - a.index;
    })
    .reverse();

  const handleChange = (value) => {
    setSearchType(value);
  };

  useEffect(() => {
    const col = { ...newKanbanCol };
    col.index =
      kanbanCols[kanbanCols.length - 1] &&
      kanbanCols[kanbanCols.length - 1].index + 1;
    setNewKanbanCol(col);
  }, [kanbanCols]);

  useEffect(() => {
    (async () => {
      const fieldsArray = [
        'name',
        'session_type',
        'status.label',
        'status.color',
        'date',
        'customers.customer.name'
      ];
      const fieldsString = fieldsArray.join(',');

      await fetchData({
        projectionFields: fieldsString
      });
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchKanbanColumns();
    })();
  }, []);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 16]}>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Select
            defaultValue={searchTypeValue || 'session'}
            style={{ width: '30%' }}
            onChange={handleChange}
          >
            <Option value="session">{t('basiclayout.menu.sessions')}</Option>
            <Option value="customer">{t('basiclayout.menu.customers')}</Option>
            <Option value="trainee">{t('basiclayout.menu.trainees')}</Option>
            <Option value="contributor">
              {t('basiclayout.menu.contributors')}
            </Option>
          </Select>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value, searchType)}
            style={{ marginLeft: '-4px', marginRight: 8 }}
          />
          <Button
            type="primary"
            disabled={
              !CheckTrialDays(user) &&
              user?.organization?.current_subscription === undefined
            }
            onClick={() => history.push(`${routes.SESSIONS}/create`)}
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col>
            {CheckTrialDays(user) ? (
              <Alert
                showIcon
                message={<span>{t('profile.trial_period')}</span>}
                type="warning"
              />
            ) : (
              user?.organization?.current_subscription === undefined && (
                <Alert
                  showIcon
                  message={
                    <span>
                      {user.organization.current_subscription
                        ? t('profile.minSubscription')
                        : t('buttons.infos.subscriptions')}
                      <Button
                        onClick={() => {
                          history.push('/profile');
                          localStorage.setItem('subscriptionIndex', '2');
                        }}
                        type="link"
                        icon={<ArrowRightOutlined />}
                      />
                    </span>
                  }
                  type="warning"
                />
              )
            )}
          </Col>
        </Row>
      </Row>
      <KanbanWrapper>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'nowrap'
          }}
        >
          {sortedKanbanCols
            .filter((col) => col.show)
            .map((col) => (
              <SessionKanbanColumns column={col} key={col._id} />
            ))}
        </div>
      </KanbanWrapper>
    </>
  );
};

export default SessionsKanbanBoard;
