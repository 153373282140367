import { useParams } from 'react-router-dom/cjs/react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Collapse, Divider, Row, Skeleton } from 'antd';
import { ContentCustom } from '../../../components';
import SessionPlanning from '../SessionPlanning/SessionPlanning';
import ModulesList from '../sessionModule/ModulesList';
import SessionCustomersTable from '../sessionCustomers/SessionCustomersTable';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { useSessionContext } from '../../../contexts/SessionContext/SessionContext';

const { Panel } = Collapse;

/**
 * @component
 * TraineesDates component displays the trainees' dates and information for a specific session.
 * It fetches data from the server and renders various components to show the session details.
 * @returns {JSX.Element} The rendered TraineesDates component.
 */
const TraineesDates = () => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const {
    traineesDates,
    setTraineesDateData,
    fetchSessionsTrainees: fetchData
  } = useSessionContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();

  const { modules, session, customers, isLoading, refresh } = traineesDates;

  const setForceRefresh = () => {
    setTraineesDateData({
      ...traineesDates,
      forceRefresh: !traineesDates.forceRefresh
    });
  };

  const updateSession = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/sessions/${id}`, body });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  return (
    <Skeleton loading={isLoading}>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={t('sessions.messages.trainees_dates')}
          />
        </Col>
      </Row>
      {modules.length > 1 && (
        <>
          <Divider orientation="left">
            <span>{t('sessions.planning')}</span>
          </Divider>
          <ContentCustom>
            <Collapse accordion ghost className="session-planning-collapse">
              <Panel key="1" header={<b>{t('sessions.planning')}</b>}>
                <SessionPlanning modules={modules} />
              </Panel>
            </Collapse>
          </ContentCustom>
          <Divider />
        </>
      )}
      <Divider orientation="left">
        <span>{t(`sessions.form.modulesList`)}</span>
      </Divider>
      <ModulesList
        contributors={session && session.contributors}
        id={id}
        refresh={refresh}
        setRefresh={() => setForceRefresh()}
        customers={session && session?.customers && session.customers}
        modules={modules}
      />
      <Divider orientation="left">
        <span>{t(`sessions.form.prospects`)}</span>
      </Divider>
      <SessionCustomersTable
        modules={modules}
        session={session}
        customers={customers.filter((el) => el.status === 'NEW')}
        fetchData={() => fetchData(true)}
        updateSession={updateSession}
        type="new"
        purpose="trainees"
        refresh={refresh}
        setRefresh={() => setForceRefresh()}
      />
    </Skeleton>
  );
};

export default TraineesDates;
