import { useTranslation } from 'react-i18next';
import moment from 'moment';
import checkAffiliateStatus from '../../../utils/checkAffiliateStatus';

const useColumns = (commissions) => {
  const { t } = useTranslation();

  return [
    {
      title: t('prescriber.affiliations.columns.status'),
      dataIndex: 'period_status',
      render: (_, record) =>
        checkAffiliateStatus(commissions ? record.sponsored._id : record, t) &&
        checkAffiliateStatus(commissions ? record.sponsored._id : record, t)
          .icon,
      filters: [
        {
          text: t('prescriber.affiliations.filter.TRIAL_PERIOD'),
          value: 'TRIAL_PERIOD'
        },
        {
          text: t('prescriber.affiliations.filter.NO_SUBSCRIPTION'),
          value: 'NO_SUBSCRIPTION'
        },
        {
          text: t('prescriber.affiliations.filter.LESS_MONTHS_OF_USE'),
          value: 'LESS_MONTHS'
        },
        {
          text: t('prescriber.affiliations.filter.MORE_MONTHS'),
          value: 'MORE_MONTHS'
        }
      ],
      onFilter: (value, record) => {
        const status = checkAffiliateStatus(
          commissions ? record.sponsored._id : record
        ).value;
        return status === value;
      }
    },

    {
      title: t('prescriber.affiliations.columns.full_name'),
      render: (user) =>
        user &&
        (commissions
          ? `${user.sponsored._id.last_name} ${user.sponsored._id.first_name}`
          : `${user.last_name} ${user.first_name}`),
      sorter: (a, b) => {
        const fullNameA = commissions
          ? `${a.sponsored._id.last_name} ${a.sponsored._id.first_name}`
          : `${a.last_name} ${a.first_name}`;
        const fullNameB = commissions
          ? `${b.sponsored._id.last_name} ${b.sponsored._id.first_name}`
          : `${b.last_name} ${b.first_name}`;
        return fullNameA.localeCompare(fullNameB);
      }
    },
    {
      title: t('prescriber.affiliations.columns.email'),
      render: (user) =>
        user && (commissions ? user.sponsored._id.email : user.email),
      sorter: (a, b) => {
        const emailA = commissions ? a.sponsored._id.email : a.email;
        const emailB = commissions ? b.sponsored._id.email : b.email;
        return emailA.localeCompare(emailB);
      }
    },
    {
      title: t(
        `prescriber.affiliations.columns.${
          commissions ? 'commission_date' : 'date'
        }`
      ),
      render: (user) =>
        user &&
        (commissions
          ? moment(user.date).format('DD-MM-YYYY')
          : moment(user.created_at).format('DD-MM-YYYY')),
      sorter: (a, b) => {
        const dateA = commissions ? a.date : a.created_at;
        const dateB = commissions ? b.date : b.created_at;
        return moment(dateA).unix() - moment(dateB).unix();
      }
    },
    ...(commissions
      ? [
          {
            title: t('prescriber.affiliations.columns.amount'),
            dataIndex: 'amount',
            render: (amount) => amount && `${amount.toFixed(2)} €`,
            sorter: (a, b) => a.amount - b.amount
          },
          {
            title: t('prescriber.affiliations.columns.payment_status'),
            dataIndex: 'payment_status',
            render: (_, record) =>
              record.status &&
              t(`prescriber.affiliations.tags.${record.status}`),
            filters: [
              {
                text: t('prescriber.affiliations.filter.PAID'),
                value: 'PAID'
              },
              {
                text: t('prescriber.affiliations.filter.WAITING'),
                value: 'WAITING'
              },
              {
                text: t('prescriber.affiliations.filter.LESS_MONTHS'),
                value: 'LESS_MONTHS'
              }
            ],
            onFilter: (value, record) => record.status === value
          }
        ]
      : [])
  ];
};

export default useColumns;
