import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from '../../Skills/fields';

export const ModalAddSKils = ({ setRefreshSkills }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const { fields } = useFields();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const [visible, setVisible] = useState(true);

  const submitForm = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: '/skills',
        body: values
      });
      setRefreshSkills((prev) => !prev);
      setVisible(false);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Modal
      title="Ajouter des compétences"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        {...formItemLayout}
        onFinish={submitForm}
        style={{ marginTop: 16 }}
      >
        {fields.map((field) => generateFields('skills', field))}
        <Row justify="center">
          <Form.Item style={{ marginTop: 16 }}>
            <Button type="add" htmlType="submit" style={{ marginTop: 16 }}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
