import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom';
import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { routes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import { ContentCustom, DescriptionList } from '../../../components';
import useListContent from '../listContent';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

/**
 * @component
 * Renders the TabGeneral component.
 * This component displays general information about a session.
 * @returns {JSX.Element} The TabGeneral component.
 */
const TabGeneral = () => {
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { notification } = ErrorStatusCode();

  const fetchData = async () => {
    setIsLoading(true);
    const fields = [
      'session_type',
      'name',
      'customer_type',
      'subcontracting',
      'training_type',
      'training_field',
      'diploma',
      'diploma_name',
      'organization'
    ];
    const fieldsString = fields.join(',');
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${id}?fields=${fieldsString}`
      });
      setSession(data);
    } catch (e) {
      if (e?.response.data.status_code === 403) history.push(routes.SESSIONS);
      if (e.response) {
        notification(e.response);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContentCustom>
      <Skeleton loading={isLoading} active>
        <DescriptionList data={useListContent(session || {})} translate />
      </Skeleton>
    </ContentCustom>
  );
};

export default TabGeneral;
