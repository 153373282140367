import React, { useEffect, useState } from 'react';
import { Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components';
import RecapQualityNotes from './RecapQualityNotes';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const QualityReport = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const { notification } = ErrorStatusCode();

  const getTable = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/quality_monitor?purpose=sessions`
      });
      setYears(data);
    } catch (e) {
      if (e.response) notification(e.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getTable();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('quality.title')} />
      <Card
        style={{ marginTop: 20 }}
        bordered={false}
        className="card-no-border"
      >
        <Space>
          {/* <Button type="link" onClick={() => onSelectType('sessions')}>{t('quality.buttons.sessions')}</Button>
          <Button type="link" onClick={() => onSelectType('programs')}>{t('quality.buttons.programs')}</Button>
          <Button type="link" onClick={() => onSelectType('contributors')}>{t('quality.buttons.contributors')}</Button>
          <Button type="link" onClick={() => onSelectType('progress')}>{t('quality.buttons.progress')}</Button> */}
        </Space>
        <RecapQualityNotes rowsData={years} type="sessions" loading={loading} />
      </Card>
    </>
  );
};

export default QualityReport;
