import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const ModalUpdateNameQuiz = ({
  idSession,
  isVisible,
  setIsVisible,
  quizzes,
  refresh,
  setRefresh,
  purpose,
  type,
  title,
  isLoading
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [name, setName] = useState('');
  const [existModel, setExistModel] = useState(false);
  const [selectedQuizModel, setSelectedQuizModel] = useState('');
  const [quizModels, setQuizModels] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);

  const onClose = () => {
    setIsVisible(!isVisible);
  };

  const createQuiz = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: '/quizzes', body });

      history.push(`${routes.SESSIONS}/show/${id}/quizzes/${data._id}`);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    setIsFieldsLoading(true);
    setExistModel(
      quizzes &&
        quizzes.filter(
          (el) =>
            !el.session &&
            el.type === `${type}_model` &&
            el.organization === user.organization._id
        ).length !== 0
    );
    setQuizModels(
      quizzes &&
        quizzes.filter(
          (el) =>
            !el.session &&
            el.type === `${type}_model` &&
            el.organization === user.organization._id
        )
    );
    setIsFieldsLoading(false);
  }, [type, quizzes]);

  const updateQuiz = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/quizzes/${id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const addQuiz = async () => {
    let body = {};
    if (purpose === 'create') {
      body = {
        name,
        session: idSession,
        type
      };
    } else {
      body = {
        name,
        type
      };
    }

    await createQuiz(body);
  };

  const updateNameQuiz = async () => {
    await updateQuiz({ name });
  };

  const addFromModel = async () => {
    const model = quizzes.find((el) => el._id === selectedQuizModel);
    setName(model && model.name);
    const body = {
      name: model && model.name,
      type,
      session: id,
      questions: model && [...model.questions]
    };

    await createQuiz(body);
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (purpose === 'edit') {
      setName(title);
    }
  }, [title]);

  useEffect(() => {
    if (purpose !== 'edit' && name !== '' && purpose === 'models') {
      const quiz = quizzes[quizzes.length - 1];
      history.push(
        `${routes.CATALOG}${subRoutes.CATALOG.QUIZZES_MODELS}/edit/${quiz._id}`
      );
    }
  }, [quizzes]);

  const handleSubmit = async () => {
    if (purpose !== 'edit') {
      await addQuiz();
    } else {
      await updateNameQuiz();
    }
    setRefresh(!refresh);
    setIsVisible(!isVisible);
  };

  return (
    <Modal
      title={"Nommer l'évaluation"}
      closable
      visible={isVisible}
      footer={false}
      onCancel={onClose}
      width="40%"
    >
      <Row style={{ marginBottom: 16 }}>
        <Col>
          {isLoading ? (
            <Spin />
          ) : (
            purpose === 'create' && (
              <Alert
                type="info"
                message={
                  existModel
                    ? `Vous pouvez créer une évaluation de ${t(
                        `sessions.quizzes.models.${type}`
                      )} depuis un modèle d'évaluation`
                    : "Vous n'avez aucun modèle d'évaluation lié à ce type"
                }
              />
            )
          )}
        </Col>
      </Row>
      {!isLoading && existModel && (
        <>
          <Row align="middle">
            <Col span={8}>
              <span>Choisir un modèle d'évaluation :</span>
            </Col>
            <Col span={16}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: '100%' }}
                loading={isFieldsLoading}
                onChange={(value) => {
                  setSelectedQuizModel(value);
                }}
              >
                {quizModels.map((model) => (
                  <Option key={model._id} value={model._id}>
                    {model.name.length > 1 ? model.name : 'model sans nom'}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Button
            style={{ float: 'right' }}
            type="link"
            onClick={() => addFromModel()}
          >
            {t(`sessions.quizzes.add_from.${type}`)}
          </Button>
          <br />
          <Divider orientation="left">
            <span>Ou</span>
          </Divider>
        </>
      )}
      <Row align="middle">
        <Col span={4}>
          <span>Intitulé :</span>
        </Col>
        <Col span={20}>
          <Input
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Col>
      </Row>

      {purpose === 'create' ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="link" onClick={() => handleSubmit()}>
            Continuer sans modèle
          </Button>
        </div>
      ) : (
        <Row style={{ marginTop: 16 }}>
          <Col span={4} offset={20}>
            <Button type="add" onClick={() => handleSubmit()}>
              Suivant
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

ModalUpdateNameQuiz.propTypes = {
  idSession: PropTypes.string,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  quizzes: PropTypes.shape,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  purpose: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool
};

ModalUpdateNameQuiz.defaultProps = {
  idSession: undefined,
  isVisible: false,
  setIsVisible: () => {},
  quizzes: [],
  refresh: false,
  setRefresh: () => {},
  purpose: 'create',
  type: 'quiz',
  title: '',
  isLoading: false
};

export default ModalUpdateNameQuiz;
