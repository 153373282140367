import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Divider, Row, Skeleton } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import ListSessionFiles from '../conventions/ListSessionFiles';
import SessionContributorsList from '../sessionContributors/SessionContributorsList';
import { routes } from '../../../utils/constants/routes';
import AssociatedProgramToSession from '../sessionProgram/AssociatedProgramToSession';
import ProgramsTable from '../sessionProgram/programsTable';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import ModalUploadFiles from '../../Catalog/Program/modalUploadFiles';
import { useFiles } from '../utils/filesFunctions';
import { useSessionContext } from '../../../contexts/SessionContext/SessionContext';

/**
 * @component
 * @returns {JSX.Element} The ProgContrib component.
 * ProgContrib component represents a tab in the Sessions section of the back-office application.
 * It displays information about the associated program, contributors, and files for a session.
 * Users can update the program, session, and delete files from the program.
 */
const ProgContrib = () => {
  const { downloadFile, deleteFile } = useFiles({});
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const { notification } = ErrorStatusCode();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    progContribDataResult,
    state,
    setProgContribData,
    setSearchValue,
    searchValue
  } = useSessionContext();

  const { fetchSession, getModules } = progContribDataResult;
  const { progContribData } = state;

  const setRefresh = (value) => {
    setProgContribData({ ...progContribData, forceRefresh: value });
  };

  const {
    session,
    programs,
    modules,
    emails,
    isLoading,
    forceRefresh
  } = progContribData;

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/programs/${session.program._id}`,
        body
      });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const updateSession = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/sessions/${id}`, body });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const deleteFileFromProgram = async (fileId) => {
    const files = session.program.files.filter((el) => el._id !== fileId);
    await updateProgram({ files });
    await deleteFile(fileId);
    await fetchSession();
  };

  return (
    <Skeleton loading={isLoading}>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={t('sessions.messages.program_contributor')}
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t(`sessions.form.sessionProgram`)}</span>
      </Divider>
      <div>
        {session?.program ? (
          <AssociatedProgramToSession
            updateSession={updateSession}
            fetchData={fetchSession}
            session={session}
          />
        ) : (
          <ProgramsTable
            fetchData={fetchSession}
            updateSession={updateSession}
            programs={programs}
            session={session}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
        )}
      </div>
      <Divider orientation="left">
        <span>{t(`sessions.form.sessionContributors`)}</span>
      </Divider>
      <div>
        {session &&
          session.contributors &&
          session.contributors.length !== 0 && (
            <SessionContributorsList
              updateSession={updateSession}
              fetchData={fetchSession}
              session={session}
              getModules={getModules}
              modules={modules}
              emails={emails}
              forceRefresh={forceRefresh}
              setForceRefresh={setRefresh}
            />
          )}
      </div>

      <Link
        to={{
          pathname: `${routes.SESSIONS}/show/${id}/contributors/create`
        }}
      >
        <Button type="primary" style={{ float: 'right', margin: 20 }}>
          {t('sessions.form.button.contributor')}
        </Button>
      </Link>

      {session?.program && (
        <>
          <Divider orientation="left">
            <span>{t('programs.form.externFiles')}</span>
          </Divider>
          <ModalUploadFiles
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            id={session.program._id}
            sessionId={session._id}
            fetchData={fetchSession}
            uploadType="programs"
          />
          <Button
            type="link"
            onClick={() => {
              setIsModalVisible(!isModalVisible);
            }}
            style={{ margin: '0 0 16px 16px' }}
          >
            {t('programs.form.title.addFiles')}
          </Button>
          <ListSessionFiles
            files={session.program.files}
            downloadFile={downloadFile}
            deleteFileFromSession={deleteFileFromProgram}
          />
        </>
      )}
    </Skeleton>
  );
};
export default ProgContrib;
