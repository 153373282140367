import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Dropdown, List } from 'antd';
import {
  DownOutlined,
  DownloadOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useListActions } from './listActions';
import useAuthContext from '../../../../../contexts/AuthContext';
import MenuDropDown from '../MenuDropDown';
import ErrorStatusCode from '../../../../../utils/ErrorStatusCode';

/**
 * Renders a list of conventions for a session.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.session - The session object.
 * @param {Function} props.setCustomerId - The function to set the customer ID.
 * @param {Array} props.contacts - The list of contacts.
 * @param {Array} props.customersList - The list of customers.
 * @param {boolean} props.isLoading - Indicates if the data is loading.
 * @param {string} props.customerId - The customer ID.
 * @param {Function} props.downloadFile - The function to download a file.
 * @param {Function} props.generateFile - The function to generate a file.
 * @param {Function} props.generateMultiFiles - The function to generate multiple files.
 * @param {Object} props.generatedFile - The generated file object.
 * @param {string} props.generateStatus - The generate status.
 * @param {Array} props.templatesPrivate - The list of private templates.
 * @param {Array} props.templatesCompany - The list of company templates.
 * @param {Function} props.setSendType - The function to set the send type.
 * @param {Function} props.setGlobalEmail - The function to set the global email.
 * @param {Function} props.showModal - The function to show the modal.
 * @param {Function} props.setUsers - The function to set the users.
 * @param {Function} props.setConventionFile - The function to set the convention file.
 * @param {Function} props.setIsModalMailVisible - The function to set the modal visibility.
 * @param {boolean} props.isModalMailVisible - Indicates if the modal is visible.
 * @param {Object} props.isConventionFiles - The convention files object.
 * @returns {JSX.Element} The rendered ConventionList component.
 */
export const ConventionList = ({
  session,
  setCustomerId,
  contacts,
  customersList,
  isLoading,
  customerId,
  downloadFile,
  generateFile,
  generateMultiFiles,
  generatedFile,
  generateStatus,
  templatesPrivate,
  templatesCompany,
  setSendType,
  setGlobalEmail,
  showModal,
  setUsers,
  setConventionFile,
  setIsModalMailVisible,
  isModalMailVisible,
  isConventionFiles
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { notification } = ErrorStatusCode();
  const [emails, setEmails] = useState([]);

  const getEmails = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails?session=${id}&populate=user`
      });
      setEmails(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    getEmails();
  }, []);

  const { listActions } = useListActions(
    session,
    setCustomerId,
    contacts,
    emails
  );

  return (
    <List
      size="small"
      dataSource={customersList}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              item.customer.name ||
              `${item.customer.first_name} ${item.customer.last_name}`
            }
          />
          <span style={{ paddingRight: 50 }}>
            {item.ref === 'Company'
              ? listActions(
                  'company_convention',
                  item.customer._id,
                  item.ref,
                  item._id
                )
              : listActions(
                  'private_convention',
                  item.customer._id,
                  item.ref,
                  item._id
                )}
          </span>
          <span style={{ float: 'right' }}>
            {isLoading && item.customer._id === customerId && (
              <LoadingOutlined />
            )}
            <Dropdown
              overlay={() =>
                item.ref === 'Company'
                  ? MenuDropDown({
                      type: 'company_convention',
                      id: item.customer._id,
                      ref: item.ref,
                      generateMultiFiles,
                      templatesPrivate,
                      generateStatus,
                      templatesCompany,
                      generateFile,
                      t
                    })
                  : MenuDropDown({
                      type: 'private_convention',
                      id: item.customer._id,
                      ref: item.ref,
                      generateMultiFiles,
                      templatesPrivate,
                      generateStatus,
                      templatesCompany,
                      generateFile,
                      t
                    })
              }
              trigger={['click']}
            >
              <span
                style={{
                  paddingLeft: 10,
                  fontSize: 15,
                  cursor: 'pointer'
                }}
              >
                {t('sessions.conventions.button.generate')}
                <DownOutlined style={{ marginLeft: 4 }} />
              </span>
            </Dropdown>
            {isConventionFiles[item._id] && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    downloadFile(
                      generatedFile[item._id].id,
                      generatedFile[item._id].fileName
                    )
                  }
                />
                <Divider type="vertical" />
                <Button
                  type="link"
                  icon={<SendOutlined />}
                  onClick={() => {
                    setSendType('one');
                    setGlobalEmail(false);
                    showModal({
                      ref: item.ref,
                      id: item.customer._id,
                      document: generatedFile[item._id],
                      setUsers,
                      customersList,
                      contacts,
                      setConventionFile,
                      files: session.files,
                      setIsModalMailVisible,
                      isModalMailVisible
                    });
                  }}
                />
              </>
            )}
          </span>
        </List.Item>
      )}
    />
  );
};

ConventionList.propTypes = {
  session: PropTypes.shape({
    files: PropTypes.shape([]).isRequired
  }),
  setCustomerId: PropTypes.func,
  contacts: PropTypes.shape([]),
  customersList: PropTypes.shape([]),
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  downloadFile: PropTypes.func,
  generateFile: PropTypes.func,
  generateMultiFiles: PropTypes.func,
  generatedFile: PropTypes.shape({}),
  generateStatus: PropTypes.shape({}),
  templatesPrivate: PropTypes.shape([]),
  templatesCompany: PropTypes.shape([]),
  setSendType: PropTypes.func,
  setGlobalEmail: PropTypes.func,
  showModal: PropTypes.func,
  setUsers: PropTypes.func,
  setConventionFile: PropTypes.func,
  setIsModalMailVisible: PropTypes.func,
  isModalMailVisible: PropTypes.bool,
  isConventionFiles: PropTypes.shape({})
};

ConventionList.defaultProps = {
  session: {
    files: []
  },
  setCustomerId: () => {},
  contacts: [],
  customersList: [],
  isLoading: false,
  customerId: '',
  downloadFile: () => {},
  generateFile: () => {},
  generateMultiFiles: () => {},
  generatedFile: {},
  generateStatus: {},
  templatesPrivate: [],
  templatesCompany: [],
  setSendType: () => {},
  setGlobalEmail: () => {},
  showModal: () => {},
  setUsers: () => {},
  setConventionFile: () => {},
  setIsModalMailVisible: () => {},
  isModalMailVisible: false,
  isConventionFiles: {}
};
