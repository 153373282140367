import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Table, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useColumns from '../../Catalog/Program/columns';
import { routes, subRoutes } from '../../../utils/constants/routes';

const { Search } = Input;

/**
 * Renders a table component for displaying programs and allows adding programs to a session.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.updateSession - Function to update the session.
 * @param {Function} props.fetchData - Function to fetch data.
 * @param {Array} props.programs - Array of programs to display in the table.
 * @param {Function} props.setBlocs - Function to set the selected blocs.
 * @param {Object} props.session - The session object.
 * @param {Function} props.setSearchValue - Function to set the search value.
 * @param {String} props.searchValue - The search value.
 * @returns {JSX.Element} The ProgramsTable component.
 */
const ProgramsTable = ({
  updateSession,
  fetchData,
  programs,
  setBlocs,
  session,
  setSearchValue,
  searchValue
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { columns } = useColumns();
  const { pathname } = useLocation();
  const history = useHistory();
  const splitUrl = pathname.split('/');

  const addProgramToSession = async () => {
    setLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `programs/${selectedRowKeys}`
    });

    if (data.files.length) {
      const newestObject = data.files.reduce((acc, obj) => {
        if (!acc || obj.date > acc.date) {
          return obj;
        }
        return acc;
      }, null);

      await updateSession({
        $push: {
          files: {
            type: 'programs',
            filename: newestObject.filename,
            date: new Date(),
            _id: newestObject._id,
            program: selectedRowKeys
          }
        }
      });
    }
    await updateSession({ program: selectedRowKeys });
    message.success('Programme ajouté !');
    await fetchData();
    setLoading(false);
  };

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
    if (splitUrl[1] === 'catalog') {
      setBlocs(keys);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => onSelectChange(keys)
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        style={{ marginLeft: '30px', marginRight: '30px' }}
      >
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            onSearch={(value) => setSearchValue(value)}
            defaultValue={searchValue}
          />
        </Col>
        {splitUrl[1] !== 'catalog' ? (
          <Row gutter={[16]} align="middle">
            <Col>
              <Button
                onClick={addProgramToSession}
                type="primary"
                loading={isLoading}
              >
                {t('sessions.form.button.program')}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() =>
                  history.push(
                    `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/create`,
                    { session }
                  )
                }
              >
                Nouveau
              </Button>
            </Col>
          </Row>
        ) : null}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={programs}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProgramsTable;

ProgramsTable.propTypes = {
  updateSession: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf({}).isRequired,
  setBlocs: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  setSearchValue: PropTypes.func,
  searchValue: PropTypes.string
};

ProgramsTable.defaultProps = {
  setSearchValue: () => {},
  searchValue: ''
};
