import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import useAuthContext from '../AuthContext';
import { useTraineesDates } from '../../routes/Sessions/Tabs/ContextFunctions/useTraineesDates';
import { initialState, sessionReducer } from './reducer';
import { useProgContrib } from '../../routes/Sessions/Tabs/ContextFunctions/useProgContrib';

const SessionContext = createContext({});

/**
 * @description Session context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Socket context provider
 */
export const SessionContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const location = useLocation();
  const currentLocation = location.pathname;
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [pathId, setPathId] = useState();
  const [state, dispatch] = useReducer(sessionReducer, initialState);
  const { traineesDates, progContribData } = state;

  const setTraineesDateData = (value) =>
    dispatch({
      type: 'SET_TRAINING_DATES',
      payload: value
    });

  const setProgContribData = (value) =>
    dispatch({
      type: 'SET_PROG_CONTRIB',
      payload: value
    });

  const {
    fetchTraineesData,
    setRefreshTrainingDates,
    fetchData: fetchSessionsTrainees
  } = useTraineesDates({
    traineesDates,
    setTraineesDateData,
    dispatchAPI,
    pathId,
    history
  });

  const progContribDataResult = useProgContrib({
    progContribData,
    setProgContribData,
    dispatchAPI,
    id: pathId,
    searchValue
  });
  const { fetchProgContribData } = progContribDataResult;

  useEffect(() => {
    if (!pathId || currentLocation !== `/sessions/show/${pathId}`) {
      return;
    }
    fetchTraineesData();
  }, [traineesDates.forceRefresh, pathId, currentLocation]);

  useEffect(() => {
    if (!pathId || currentLocation !== `/sessions/show/${pathId}`) {
      return;
    }
    fetchProgContribData();
  }, [progContribData.forceRefresh, pathId, currentLocation, searchValue]);

  return (
    <SessionContext.Provider
      value={{
        traineesDates,
        setTraineesDateData,
        setRefreshTrainingDates,
        fetchSessionsTrainees,
        setProgContribData,
        progContribDataResult,
        setPathId,
        state,
        setSearchValue,
        searchValue
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  return useContext(SessionContext);
};
