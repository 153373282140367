import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Row,
  Select,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import ModalUploadFiles from '../../../Catalog/Program/modalUploadFiles';
import useAuthContext from '../../../../contexts/AuthContext';
import ListSessionFiles from '../ListSessionFiles';
import ModalSendMails from '../../Convocations/modalSendMails';
import ModalGeneratingFile from '../../Convocations/modalGeneratingFile';
import ErrorStatusCode from '../../../../utils/ErrorStatusCode';
import MenuDropDownCGV from './MenuDropDownCGV';
import MenuDropDown from './MenuDropDown';
// Function
import getCGVTemplates from './utils/getCGVTemplates';
import getCInternalRulesTemplates from './utils/getCInternalRulesTemplates';
import getFilesPrivate from './utils/getFilesPrivate';
import getFilesCompany from './utils/getFilesCompany';
import showModal from './utils/showModal';
import checkIfConventionFiles from './utils/checkIfConventionFiles';
import { useFiles } from '../../utils/filesFunctions';
import { ConventionList } from './utils/ConventionList';

const { Option } = Select;

const GenerateConventions = ({
  customersList,
  fetchData,
  session,
  updateSession,
  contacts,
  forceRefresh,
  setForceRefresh,
  emails
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [conventionFile, setConventionFile] = useState([]);
  const [generatedFile, setGeneratedFile] = useState({});
  const [isConventionFiles, setIsConventionFiles] = useState({});
  const [templatesPrivate, setTemplatesPrivate] = useState([]);
  const [templatesCompany, setTemplatesCompany] = useState([]);
  const [sendType, setSendType] = useState('one');
  const [generateStatus, setGenerateStatus] = useState(false);
  const [internalRulesList, setInternalRulesList] = useState([]);
  const [users, setUsers] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [cgvTemplates, setCGVTemplates] = useState([]);
  const [globalEmail, setGlobalEmail] = useState(true);
  const { notification } = ErrorStatusCode();

  const {
    deleteFile,
    downloadFile,
    downloadCGVFile,
    generateFile,
    generateMultiFiles
  } = useFiles({
    setIsLoading,
    setGenerateStatus,
    setIsModalGenerateFileVisible,
    fetchData,
    session,
    isModalGenerateFileVisible,
    customersList
  });

  useEffect(() => {
    (async () => {
      getFilesCompany({ setTemplatesCompany, user, notification });
      getFilesPrivate({ setTemplatesPrivate, user, notification });
      await getCGVTemplates({
        setCGVTemplates,
        notification,
        dispatchAPI,
        user
      });
      await getCInternalRulesTemplates({
        dispatchAPI,
        setInternalRulesList,
        user,
        notification
      });
    })();
  }, []);

  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };

  useEffect(() => {
    checkIfConventionFiles({
      customersList,
      files: session.files,
      setIsConventionFiles,
      setGeneratedFile
    });
  }, [session?.files, customersList]);

  const selectInternalRulesFile = async (value) => {
    try {
      const tmp = internalRulesList.find((el) => el._id === value);
      const internalRulesFile = {
        _id: tmp._id,
        filename: tmp.metadata.originalName
      };
      await updateSession({ internal_rules: internalRulesFile });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) {
        ErrorStatusCode(e.response);
      }
    }
  };

  const returnValueRulesSelect = () => {
    if (session?.internal_rules?._id) {
      return session?.internal_rules?._id;
    }
    if (internalRulesList.length === 1) {
      return internalRulesList[0]._id;
    }
    return null;
  };

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={t('sessions.conventions.message.next_step')}
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t('sessions.conventions.button.generate_conventions')}</span>
      </Divider>
      <Card>
        {!customerId && (
          <Col style={{ marginBottom: '20px' }}>
            <Alert
              showIcon
              type="info"
              message={t('sessions.conventions.message.no_customers')}
            />
          </Col>
        )}
        <ModalGeneratingFile
          isVisible={isModalGenerateFileVisible}
          setVisible={setIsModalGenerateFileVisible}
        />
        <ModalSendMails
          emailModel="convention_model"
          files={conventionFile}
          file={conventionFile}
          sendType={sendType}
          isSelectedDocument={false}
          globalEmail={globalEmail}
          setGlobalEmail={setGlobalEmail}
          users={users}
          isModalVisible={isModalMailVisible}
          setIsModalVisible={setIsModalMailVisible}
          emailPurpose="conventions"
          refresh={forceRefresh}
          setRefresh={setForceRefresh}
          session={session}
        />
        <Button
          style={{ marginBottom: 16 }}
          disabled={Object.keys(generatedFile).length === 0}
          type="primary"
          onClick={() => {
            setSendType('multi');
            setGlobalEmail(true);
            showModal({
              ref: 'all',
              setUsers,
              customersList,
              contacts,
              setConventionFile,
              files: session.files,
              setIsModalMailVisible,
              isModalMailVisible
            });
          }}
        >
          {t('sessions.conventions.button.send_conventions')}
        </Button>
        <Spin style={{ marginLeft: 10 }} spinning={generateStatus} />
        <Dropdown
          overlay={MenuDropDown({
            type: 'multi',
            generateMultiFiles,
            templatesPrivate,
            generateStatus,
            templatesCompany,
            generateFile,
            t
          })}
          disabled={!customersList.length}
        >
          <Button type="link" style={{ marginBottom: 10, marginLeft: 10 }}>
            {t('sessions.conventions.button.generate_conventions')}
            <DownOutlined />
          </Button>
        </Dropdown>
        <ConventionList
          session={session}
          setCustomerId={setCustomerId}
          contacts={contacts}
          emails={emails}
          customersList={customersList}
          isLoading={isLoading}
          customerId={customerId}
          downloadFile={downloadFile}
          generateFile={generateFile}
          generateMultiFiles={generateMultiFiles}
          generatedFile={generatedFile}
          generateStatus={generateStatus}
          templatesPrivate={templatesPrivate}
          templatesCompany={templatesCompany}
          setSendType={setSendType}
          setGlobalEmail={setGlobalEmail}
          showModal={showModal}
          setUsers={setUsers}
          setConventionFile={setConventionFile}
          setIsModalMailVisible={setIsModalMailVisible}
          isModalMailVisible={isModalMailVisible}
          isConventionFiles={isConventionFiles}
        />
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.internal_rules.title')}</span>
      </Divider>
      <Card>
        <Select
          value={returnValueRulesSelect()}
          onSelect={(value) => selectInternalRulesFile(value)}
          style={{ width: 500 }}
        >
          {internalRulesList.map((internal_rule) => (
            <Option key={internal_rule._id} value={internal_rule._id}>
              {internal_rule.metadata.originalName}
            </Option>
          ))}
        </Select>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.general_terms.title')}</span>
      </Divider>
      <Card>
        <Row>
          <Col span={2}>
            <Dropdown
              disabled={!cgvTemplates.length}
              trigger={['click']}
              overlay={() =>
                MenuDropDownCGV({ cgvTemplates, downloadCGVFile, t })
              }
            >
              <Button type="link">
                {t('sessions.general_terms.button.terms')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={12}>
            {!cgvTemplates.length && (
              <Alert
                type="warning"
                message={t('sessions.general_terms.message.no_models')}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.conventions.archives')}</span>
      </Divider>
      <Card>
        <Button
          type="link"
          onClick={() => {
            setIsModalVisible(!isModalVisible);
          }}
        >
          {t('sessions.conventions.button.upload')}
        </Button>
        <ModalUploadFiles
          uploadType="sessions"
          isModalVisible={isModalVisible}
          fetchData={fetchData}
          setIsModalVisible={setIsModalVisible}
          id={session && session._id}
          fileType="convention"
        />
        {session &&
          session.files &&
          session.files.filter((el) => el.type === 'convention').length !==
            0 && (
            <ListSessionFiles
              files={session.files.filter((el) => el.type === 'convention')}
              deleteFileFromSession={deleteFileFromSession}
              downloadFile={downloadFile}
            />
          )}
      </Card>
    </>
  );
};

GenerateConventions.propTypes = {
  customersList: PropTypes.arrayOf({}).isRequired,
  session: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.arrayOf({}),
    internal_rules: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  contacts: PropTypes.arrayOf({}).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  emails: PropTypes.arrayOf({}).isRequired,
  fetchData: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

export default GenerateConventions;
