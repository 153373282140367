import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

/**
 * Custom hook for handling file-related operations.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.setGenerateStatus - The function to set the generate status.
 * @param {Function} options.setIsModalGenerateFileVisible - The function to set the visibility of the modal for generating files.
 * @param {Function} options.fetchData - The function to fetch data.
 * @param {Object} options.session - The session object.
 * @param {boolean} options.isModalGenerateFileVisible - The flag indicating whether the modal for generating files is visible.
 * @param {Array} options.customersList - The list of customers.
 * @returns {Object} - An object containing file-related functions.
 */
export const useFiles = ({
  setIsLoading,
  setGenerateStatus,
  setIsModalGenerateFileVisible,
  fetchData,
  session,
  isModalGenerateFileVisible,
  customersList
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();

  const downloadFile = async (fileId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${fileId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteFile = async (fileId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${fileId}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const downloadCGVFile = async (type, tempId, tempName) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/cgv/${tempId}?fileType=${type}&purpose=cgv`
      });
      const bufferArray = new Uint8Array(response.data.data);
      const blob = new Blob([bufferArray], {
        type: type === 'docx' ? 'application/docx' : 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${tempName}.${type}`;
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const generateFile = async ({ id, customId, fileType }) => {
    try {
      message.info(t('sessions.messages.is_generating'), 5);
      setIsLoading(true);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/files/generate/convention/${session._id}/${customId}/${id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      setGenerateStatus(false);
      setIsLoading(false);
      await fetchData();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const generateMultiFiles = async (typeFile) => {
    try {
      message.info(t('sessions.messages.is_generating'), 5);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/multi-generate/${session._id}?customers=${JSON.stringify(
          customersList.map(({ customer }) => customer._id)
        )}&fileType=${typeFile}`
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setIsLoading(false);
      setGenerateStatus(false);
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  return {
    downloadFile,
    deleteFile,
    downloadCGVFile,
    generateFile,
    generateMultiFiles
  };
};
