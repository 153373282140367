import React, { useEffect, useState } from 'react';
import {
  Card,
  Divider,
  Col,
  Select,
  Row,
  TreeSelect,
  message,
  Button,
  Collapse,
  Dropdown,
  Menu,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import * as moment from 'moment';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import SessionCustomersTable from '../sessionCustomers/SessionCustomersTable';
import AttendanceTraineesRecordTable from './AttendanceTraineesRecordTable';
import AttendanceContributorsRecordTable from './AttendanceContributorsRecordTable';
import ListSessionFiles from '../conventions/ListSessionFiles';
import ModalUploadFiles from '../../Catalog/Program/modalUploadFiles';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;
const { TreeNode } = TreeSelect;
const { Panel } = Collapse;

const Signings = ({
  customers,
  contributors,
  trainees,
  modules,
  session,
  fetchData,
  updateSession,
  setEmails
}) => {
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [isDownloading, setIsDownloading] = useState(false);
  const [file, setFile] = useState({});
  const [users, setUsers] = useState([]);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const [generatedFile, setGeneratedFile] = useState({});
  const [customer, setCustomer] = useState('Tous');
  const [exist, setExist] = useState(false);
  const [editSignings, setEditSignings] = useState(false);
  const [bool, setBool] = useState(false);
  const [slots, setSlots] = useState([]);
  const [signingType, setSigningType] = useState('');
  const [signingModule, setSigningModule] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signingModules, setSigningModules] = useState(['Tous les modules']);
  const [loading, setLoading] = useState(false);
  const [generateStatus, setGenerateStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [signingsTemplates, setSigningsTemplates] = useState([]);

  const signingTypes = [
    {
      value: 'signings_day',
      name: t('sessions.signs.signings_day')
    },
    {
      value: 'signings_trainee',
      name: t('sessions.signs.signings_trainee')
    },
    {
      value: 'signings_slot',
      name: t('sessions.signs.signings_slot')
    },
    {
      value: 'signings_week',
      name: t('sessions.signs.signings_week')
    },
    {
      value: 'signings_weekend',
      name: t('sessions.signs.signings_weekend')
    }
  ];

  const getSigingsTemplates = () => {
    try {
      const list = user.organization.templates
        .filter((el) =>
          signingTypes.map((typeEl) => typeEl.value).includes(el.type)
        )
        .map((el) => ({
          ...el,
          name: signingTypes.find((typeEl) => typeEl.value === el.type).name,
          type: signingTypes.find((typeEl) => typeEl.value === el.type).value
        }));
      setSigningsTemplates(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };
  const getFileReport = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'digital_signing_report'
      );
      setTemplates(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    getFileReport();
    getSigingsTemplates();
  }, []);

  const updateModules = async (idModule, idDate, idSubSlot, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/module/${idModule}/${idDate}/${idSubSlot}`,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const deleteFile = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const onSaveSign = () => {
    slots.forEach(async (slot) => {
      const date = modules
        .filter((el) => el._id === slot.idModule)[0]
        .slots.date.find(
          (date) => date._id.toString() === slot.idDate.toString()
        );
      const sub = date.sub_slots.find(
        (el) => el._id.toString() === slot.id.toString()
      );
      const subSlots = date.sub_slots.filter(
        (el) => el._id.toString() !== slot.id.toString()
      );
      let body = {};
      if (slot.type === 'Matin') {
        body = {
          ...date,
          sub_slots: [sub, ...subSlots]
        };
      } else {
        body = {
          ...date,
          sub_slots: [...subSlots, sub]
        };
      }
      await updateModules(slot.idModule, slot.idDate, slot.idSubSlot, body);
    });
    setEditSignings(!editSignings);
  };
  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };
  const onSelect = (checked, slot, indexSlot, contributorId) => {
    const signings = slot.signings;
    const listSlots = slots;
    if (checked === true) {
      signings.push({
        has_signed: checked,
        user: contributorId,
        ref: 'Contributor'
      });
      listSlots[indexSlot].signings = signings;
    } else {
      const list = signings.filter(
        (el) => el.user.toString() !== contributorId.toString()
      );
      listSlots[indexSlot].signings = list;
    }
    setSlots(listSlots);
    setBool(!bool);
  };

  useEffect(() => {
    const list = [];
    modules.forEach((module) => {
      list.push(module.name);
    });
    setSigningModules(['Tous les modules', ...list]);
  }, [modules]);

  useEffect(() => {
    session.files &&
      session.files.map((file) => {
        if (file.user && file.user.toString() === customer.toString()) {
          setFile(file);
        } else {
          setFile({});
        }
      });
  }, [customer, signingModule, signingType]);

  const generateFile = async (
    id,
    name,
    custo,
    signType,
    signModule,
    fileType,
    generateType
  ) => {
    try {
      message.info(t('sessions.signs.messages.loading_document'), 5);
      setLoading(true);
      setGenerateStatus(true);
      const response = await dispatchAPI('GET', {
        url: `/files/generate/signings/${session._id}/${custo}/${id}?type=${signType}&moduleName=${signModule}&fileType=${fileType}`,
        responseType: 'blob'
      });
      if (generateType === 'signings') {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name.split('.')[0]}.${fileType}`;
        a.target = '_blank';
        a.click();
      }
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setLoading(false);
      setGenerateStatus(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const listActions = () => {
    let actions = [];
    const file =
      session.files &&
      session.files
        .filter((f) => f.type === 'digital_signing_report')
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
    if (file) {
      actions = [
        <>
          <Tag style={{ fontSize: 15 }} color="orange">
            <span>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              {t('sessions.signs.generate_the', {
                date: moment(file.date).format('DD-MM-YYYY')
              })}
            </span>
            <span>
              {t('sessions.signs.format', {
                format: file.filename.split('.')[1]
              })}
            </span>
          </Tag>
        </>
      ];
    }
    return actions;
  };

  useEffect(() => {
    const file =
      session.files &&
      session.files
        .filter((f) => f.type === 'digital_signing_report')
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
    if (file) {
      setExist(true);
      setGeneratedFile({
        fileName: file.filename,
        id: file._id
      });
    }
  }, [session.files]);

  const menuDropSigningDown = () => {
    const menu = (
      <Menu>
        {signingsTemplates
          .filter((el) => el.type === signingType)
          .map((el) => (
            <>
              <Menu.Item key="1">
                {generateStatus ? (
                  <Button type="link" disabled>
                    {`${el.name.split('.')[0]} docx`}
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={() =>
                      generateFile(
                        el._id,
                        el.name,
                        customer,
                        '',
                        signingModule,
                        'docx',
                        'signings'
                      )
                    }
                  >
                    {`${el.name.split('.')[0]} docx`}
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item key="2">
                {generateStatus ? (
                  <Button type="link" disabled>
                    {`${el.name.split('.')[0]} pdf`}
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={() =>
                      generateFile(
                        el._id,
                        el.name,
                        customer,
                        '',
                        signingModule,
                        'pdf',
                        'signings'
                      )
                    }
                  >
                    {`${el.name.split('.')[0]} pdf`}
                  </Button>
                )}
              </Menu.Item>
            </>
          ))}
      </Menu>
    );
    return menu;
  };

  const menuDropDown = () => {
    const menu = (
      <Menu>
        {templates.map((el, index) => (
          <>
            <Menu.Item key={`${el._id}-${index + 1}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() =>
                    generateFile(
                      el._id,
                      el.name,
                      customer,
                      '',
                      'Tous les modules',
                      'docx',
                      'report'
                    )
                  }
                >
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key={`${el._id}-${index}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() =>
                    generateFile(
                      el._id,
                      el.name,
                      customer,
                      '',
                      'Tous les modules',
                      'pdf',
                      'report'
                    )
                  }
                >
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };

  const onChange = (value) => {
    setCustomer(value);
    const list = [];
    customers.forEach((c) => {
      if (c._id.toString() === value.toString()) {
        list.push(c);
      }
    });

    trainees.forEach((trainee) => {
      if (trainee._id.toString() === value.toString()) {
        if (!list.length) {
          list.push(trainee);
        }
      }
    });
    setUsers(list);
  };

  const createEmails = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: '/emails/many', body });

      if (data) {
        setEmails((prev) => [...prev, ...data]);
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const sendSigningLink = async () => {
    setIsLoading(true);
    const emails = [];
    const urlMail = '/email_sender/multi';
    const traineesIds = customers.flatMap((c) =>
      c.trainees_list.map((trainee) => trainee._id)
    );
    (customers || []).map(
      (c) => c.ref === 'Trainees' && traineesIds.push(c.customer._id)
    );
    const contributorsIds = contributors.map(
      (contributor) => contributor.contributor._id
    );
    const ids = [...traineesIds, ...contributorsIds];
    const subject = "Lien d'émargement";
    const body = `
      <p>Bonjour {{params.customer_name}},</p>
      <p>Nous vous invitons à venir vous émarger sur l'extranet via ce lien :</p>
      <p>{{params.url}}</p>
      <p>Merci !</p>
      <p>--</p>
      <p>{{params.organization_name}} .</p> 
    `;

    try {
      await dispatchAPI('POST', {
        url: urlMail,
        body: {
          id,
          ids: JSON.stringify(ids),
          files: '[]',
          body: JSON.stringify(body),
          subject,
          organizationId: user.organization._id
        }
      });

      customers.flatMap((c) =>
        c.trainees_list.forEach((el) => {
          emails.push({
            type: 'send_signing_link',
            name: 'Email émargement',
            subject,
            session: session || id,
            user: el,
            data: body,
            organization: user.organization._id,
            default: true,
            ref: 'Trainees'
          });
        })
      );

      contributors.forEach((el) => {
        emails.push({
          type: 'send_signing_link',
          name: 'Email émargement',
          subject,
          session: session || id,
          user: el.contributor._id,
          data: body,
          organization: user.organization._id,
          default: true,
          ref: 'Contributor'
        });
      });

      await createEmails(emails);

      message.success(t('sessions.signs.messages.signing_link_sent'));
    } catch (e) {
      if (e.response) message.error(e.response);
    }
    setIsLoading(false);
  };
  return (
    <>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <Divider orientation="left">
        <span>{t('sessions.signs.trainees_list')}</span>
      </Divider>
      <Row justify="end">
        <Button
          type="primary"
          icon={isLoading ? <LoadingOutlined /> : null}
          onClick={() => sendSigningLink()}
          style={{ margin: 32 }}
        >
          {t('sessions.signs.send_signing_link')}
        </Button>
      </Row>
      <SessionCustomersTable
        session={session}
        customers={customers}
        purpose="signings"
      />
      <Divider orientation="left">
        <span>{t('sessions.signs.download_signings')}</span>
      </Divider>
      <Card>
        <Row align="middle">
          <Col span={4}>
            <span>{t('sessions.signs.generate_signings_for')}</span>
          </Col>
          <Col span={4}>
            <TreeSelect
              value={customer}
              onChange={(value, title) => onChange(value, title)}
              style={{ width: '100%' }}
              treeDefaultExpandAll
              placeholder="Clients"
            >
              <TreeNode value="Tous" title="Tous" />
              {customers
                .filter((el) => el.ref === 'Company')
                .map(({ customer, trainees_list }) => (
                  <TreeNode title={customer.name} value={customer._id}>
                    {trainees_list.map((el) => (
                      <TreeNode
                        title={`${el.last_name} ${el.first_name}`}
                        value={el._id}
                      />
                    ))}
                  </TreeNode>
                ))}
              {customers
                .filter((el) => el.ref === 'Trainees')
                .map((el) => (
                  <TreeNode
                    value={el.key}
                    title={`${el.customer.last_name} ${el.customer.first_name}`}
                  />
                ))}
            </TreeSelect>
          </Col>
          <Col offset={1} span={4}>
            <Select
              onSelect={(value) => setSigningModule(value)}
              style={{ width: '100%' }}
              value={signingModule}
              placeholder={t('sessions.signs.select_modules')}
            >
              {signingModules.map((c) => (
                <Option key={c} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }} align="middle">
          <Col span={4}>
            <span>{t('sessions.signs.generate_signings_by')}</span>
          </Col>
          <Col span={9}>
            <Select
              onSelect={(value) => setSigningType(value)}
              value={signingType}
              style={{ width: '100%' }}
              placeholder={t('sessions.signs.select_signings')}
            >
              {signingsTemplates.map((c) => (
                <Option key={c.type} value={c.type}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col offset={1} span={4}>
            {isLoading && <LoadingOutlined />}
            <Dropdown
              disabled={
                !(signingType !== '' && signingModule !== '' && customer !== '')
              }
              overlay={() => menuDropSigningDown()}
              trigger={['click']}
            >
              <Button type="link">
                {t('sessions.signs.generate')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.signs.attendance_record')}</span>
      </Divider>
      <Card>
        <Collapse defaultActiveKey={['1', '2']} ghost>
          <Panel key="1" header={t('sessions.signs.trainees')}>
            <AttendanceTraineesRecordTable
              modules={modules}
              trainees={trainees}
              onSaveSign={onSaveSign}
              slots={slots}
              onSelect={onSelect}
              editSignings={editSignings}
              setEditSignings={setEditSignings}
              setSlots={setSlots}
            />
          </Panel>
          <Panel key="2" header={t('sessions.signs.contributors')}>
            <AttendanceContributorsRecordTable
              modules={modules}
              onSaveSign={onSaveSign}
              slots={slots}
              setSlots={setSlots}
              onSelect={onSelect}
              editSignings={editSignings}
              setEditSignings={setEditSignings}
              contributors={contributors}
            />
          </Panel>
        </Collapse>
      </Card>
      <Divider orientation="left">
        <span>{t(t('sessions.signs.signing_report'))}</span>
      </Divider>
      <Card>
        <Row align="middle">
          <Col span={4}>
            <span>{t('sessions.signs.generate_signings_for')}</span>
          </Col>
          <Col span={4}>
            <TreeSelect
              value={customer}
              onChange={(value) => onChange(value)}
              style={{ width: '100%' }}
              treeDefaultExpandAll
            >
              <TreeNode value="Tous" title="Tous" />
              {customers
                .filter((el) => el.ref === 'Company')
                .map(({ customer, trainees_list }) => (
                  <TreeNode title={customer.name} value={customer._id}>
                    {trainees_list.map((el) => (
                      <TreeNode
                        title={`${el.last_name} ${el.first_name}`}
                        value={el._id}
                      />
                    ))}
                  </TreeNode>
                ))}
              {customers
                .filter((el) => el.ref === 'Trainees')
                .map((el) => (
                  <TreeNode
                    value={el.key}
                    title={`${el.customer.last_name} ${el.customer.first_name}`}
                  />
                ))}
            </TreeSelect>
          </Col>
          <Col span={12} offset={1}>
            <>
              {listActions()}
              {isLoading && <LoadingOutlined />}
              <Dropdown overlay={() => menuDropDown()} trigger={['click']}>
                <Button type="link">
                  {t('sessions.signs.signing_report')}

                  <DownOutlined />
                </Button>
              </Dropdown>
              {exist && (
                <>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      downloadFile(generatedFile.id, generatedFile.fileName)
                    }
                  />
                </>
              )}
            </>
          </Col>
        </Row>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.signs.archive')}</span>
      </Divider>
      <Card>
        <Collapse ghost accordion>
          <Panel key="1" header={t('sessions.signs.signing_archive')}>
            <Button
              type="link"
              onClick={() => {
                setIsModalVisible(!isModalVisible);
              }}
            >
              {t('sessions.signs.generate_doc')}
            </Button>
            {session.files &&
              session.files.filter((el) => el.type).length !== 0 && (
                <ListSessionFiles
                  files={
                    session.files &&
                    session.files.filter((el) => el.type === 'signings')
                  }
                  deleteFileFromSession={deleteFileFromSession}
                  downloadFile={downloadFile}
                />
              )}
            <ModalUploadFiles
              uploadType="sessions"
              fetchData={fetchData}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
              id={session._id}
              fileType="signings"
            />
          </Panel>
        </Collapse>
      </Card>
    </>
  );
};
Signings.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contributors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  session: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  setEmails: PropTypes.func.isRequired
};
export default Signings;
