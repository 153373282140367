import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import Repository from './SessionCard';
import useBoxContext from './SessionsContext';
import { kanbanIcon } from '../../Settings/utilsKanban';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import {
  ButtonContainer,
  CardContainer,
  Col,
  LoadMoreButton,
  Title
} from './utils/kanbanStyledComponents';

/**
 * Renders the columns for the session kanban board.
 *
 * @component
 * @param {Object} column - The column object containing properties like _id, label, icon, and color.
 * @returns {JSX.Element} The rendered SessionKanbanColumns component.
 */
const SessionKanbanColumns = ({ column: { _id, label, icon, color } }) => {
  const { dispatchAPI } = useAuthContext();
  const { updateSession, searchValue, searchTypeValue } = useBoxContext();
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [isLoading, setIsLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [skip, setSkip] = useState(0); // State to keep track of how many sessions to skip
  const [refresh, setRefresh] = useState(false);
  const [totalSessions, setTotalSessions] = useState(0);
  const location = useLocation().pathname;

  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      if (label === 'CLOTUREE') {
        await updateSession(item.id, { status: _id });
        setTimeout(() => {
          setRefresh(true);
        }, 500);
      } else {
        message.warning(t('sessions.messages.drag_drop_warning'));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const fetchData = async (forceRefresh) => {
    const acceptedLabels = [
      'A VENIR',
      'A PLANIFIER',
      'EN COURS',
      'TERMINEE',
      'CLOTUREE'
    ];
    if (!acceptedLabels.includes(label)) {
      return;
    }
    setIsLoading(true);

    const searchUrl = searchValue
      ? `filter={"$text": {"$search":"${searchValue}", "$searchType":"${searchTypeValue}"}}&`
      : '';

    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `/sessions?${searchUrl}&label=${_id}&populate=customers.customer,status&fields=name,session_type,status.label,status.color,date,customers.customer.name&sessionsTab=true&skip=${skip}&limit=10&sort=-min_start_date`
      });

      if (forceRefresh) {
        setSessions(data);
      } else {
        setSessions((prevSessions) => [...prevSessions, ...data]);
      }
      const total = parseInt(headers['x-total-count'], 10);
      setTotalSessions(total);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(!false);
      if (e.response) {
        notification(e.response);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [skip]);

  useEffect(() => {
    if (location !== '/sessions' && location !== '/sessions/') {
      return;
    }
    setSkip(0);
    fetchData(true);
  }, [refresh, location, searchValue, searchTypeValue]);

  const handleLoadMore = () => {
    setSkip((prevSkip) => prevSkip + 10);
  };

  return (
    <Spin spinning={isLoading}>
      <Col ref={drop}>
        <Title>
          <div id="icon">
            {kanbanIcon.filter((ic) => ic.label === icon)[0]?.value}
          </div>
          <div id="label">{label}</div>
        </Title>
        <CardContainer>
          {sessions
            .filter(
              (session) =>
                session.status._id === _id ||
                session.status === _id ||
                session.status.label === label
            )
            .map((session, index) => (
              <React.Fragment key={session._id}>
                <Repository
                  color={color}
                  session={session}
                  forceRefresh={() => setRefresh(!refresh)}
                  columnLabel={label}
                />
                {index === sessions.length - 1 &&
                  sessions.length < totalSessions && (
                    <ButtonContainer>
                      <LoadMoreButton onClick={handleLoadMore}>
                        {t('datatable.column.action.loadMore')}
                        <DownOutlined />
                      </LoadMoreButton>
                    </ButtonContainer>
                  )}
              </React.Fragment>
            ))}
        </CardContainer>
      </Col>
    </Spin>
  );
};

SessionKanbanColumns.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string
  }).isRequired
};

export default SessionKanbanColumns;
