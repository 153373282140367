import React from 'react';
import { Modal, Row, Col, Radio, Checkbox, Rate, Card, List } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChoiceGridTable from './ChoiceGridTable';

const IndividualResponsesDetails = ({
  isVisible,
  setIsVisible,
  response,
  quiz
}) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const onClose = () => {
    setIsVisible(!isVisible);
  };

  const checkRender = (type, options, indexQuestion, item) => {
    const list = response[0].responses
      .filter((el) => el.question.toString() === item._id.toString())
      .map((el) => el.response)[0];
    switch (type) {
      case 'UNIQUE_CHOICE':
        {
          return (
            <>
              {options.map((el) => {
                return (
                  <Row>
                    <Col span={24}>
                      <Radio
                        disabled
                        checked={list.includes(el._id)}
                        value={el._id}
                      />
                      <span>{el.title}</span>
                    </Col>
                  </Row>
                );
              })}
            </>
          );
        }
        break;
      case 'MULTI_CHOICE':
        {
          return (
            <>
              {options.map((el) => (
                <Row>
                  <Col span={24}>
                    <Checkbox checked={list.includes(el._id)} disabled />
                    <span> {el.title} </span>
                  </Col>
                </Row>
              ))}
            </>
          );
        }
        break;
      case 'TEXT_AREA':
        {
          return <TextArea value={list[0]} disabled />;
        }
        break;
      case 'SCORE':
        {
          return <Rate value={list[0]} disabled />;
        }
        break;
      case 'CHOICE_GRID': {
        return (
          <ChoiceGridTable
            indexQuestion={indexQuestion}
            questionsOptions={item.questions_list}
            options={options}
            responses={response}
            questionsList={item.questions_list}
            purpose="show"
          />
        );
      }
    }
  };

  return (
    <>
      <Modal
        title={t('sessions.quizzes.show.name', {
          name: `${response[0]?.user.last_name} ${response[0]?.user.first_name}`
        })}
        width={1000}
        closable
        visible={isVisible}
        footer={false}
        onCancel={onClose}
      >
        <Card style={{ marginTop: '20px' }}>
          <List
            grid={{ column: 1 }}
            dataSource={quiz?.questions}
            renderItem={(item, index) => (
              <List.Item>
                <Card bordered={false} title={item.title}>
                  {checkRender(item.type, item.answer_options, index, item)}
                </Card>
              </List.Item>
            )}
          />
        </Card>
      </Modal>
    </>
  );
};

export default IndividualResponsesDetails;
