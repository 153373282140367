import { routes } from '../../../../utils/constants/routes';
import ErrorStatusCode from '../../../../utils/ErrorStatusCode';

/**
 * Custom hook for handling trainees dates data.
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.traineesDates - The trainees dates data.
 * @param {Function} options.setTraineesDateData - The function to set the trainees date data.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {string} options.pathId - The path ID.
 * @param {Object} options.history - The history object.
 * @returns {Object} - An object containing the fetchTraineesData and fetchData functions.
 */
export const useTraineesDates = ({
  traineesDates,
  setTraineesDateData,
  dispatchAPI,
  pathId,
  history
}) => {
  const { notification } = ErrorStatusCode();

  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/module?session=${pathId}&populate=slots.date.sub_slots.contributors,training_place.training_place`
      });
      return { modules: data };
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
      throw e;
    }
  };

  const fetchData = async ({ isolated }) => {
    if (isolated) {
      setTraineesDateData({
        ...traineesDates,
        isLoading: true
      });
    }

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${pathId}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          {
            path: 'customers',
            populate: [
              { path: 'customer' },
              { path: 'trainees_list' },
              { path: 'funders', populate: { path: 'funder' } }
            ]
          }
        ])}`
      });

      if (isolated) {
        return setTraineesDateData({
          ...traineesDates,
          session: data,
          customers: data.customers.map(({ customer, ...c }) => ({
            ...c,
            customer,
            key: customer._id
          })),
          isLoading: false
        });
      }

      return {
        session: data,
        customers: data.customers.map(({ customer, ...c }) => ({
          ...c,
          customer,
          key: customer._id
        }))
      };
    } catch (e) {
      if (e?.response.data.status_code === 403) history.push(routes.SESSIONS);
      if (e.response) {
        notification(e.response);
      }
      throw e;
    }
  };

  const fetchTraineesData = async () => {
    setTraineesDateData({ ...traineesDates, isLoading: true });
    try {
      const [modulesData, sessionData] = await Promise.all([
        getModules(),
        fetchData({})
      ]);

      setTraineesDateData({
        ...traineesDates,
        ...modulesData,
        ...sessionData,
        isLoading: false
      });
    } catch (e) {
      if (e.response) notification(e.response);
      setTraineesDateData({ ...traineesDates, isLoading: false });
    }
  };

  return {
    fetchTraineesData,
    fetchData
  };
};
