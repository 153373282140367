import { useTranslation } from 'react-i18next';

const useListContent = ({
  session_type,
  name,
  customer_type,
  subcontracting,
  training_type,
  training_field,
  diploma,
  diploma_name
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'sessions.form.session_type',
      content: t(`${session_type}`)
    },
    {
      label: 'sessions.form.name',
      content: name
    },

    {
      label: 'sessions.form.customer_type',
      content: t(`${customer_type}`)
    },
    {
      label: 'sessions.form.subcontracting',
      content: subcontracting ? t('radio.yes') : t('radio.no')
    },
    {
      label: 'sessions.form.training_type',
      content: training_type
    },
    {
      label: 'sessions.form.training_field',
      content: training_field
    },
    {
      label: 'sessions.form.diploma',
      content: diploma
    },
    {
      label: 'sessions.form.diploma_name',
      content: diploma_name
    }
  ];
};

export default useListContent;
