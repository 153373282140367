import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, message, Input, Switch, Divider } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const ModalSendMails = ({
  isModalVisible,
  setIsModalVisible,
  module,
  purpose,
  forceRefresh,
  setForceRefresh,
  setRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const { notification } = ErrorStatusCode();
  const { t } = useTranslation();
  const [distance, setDistance] = useState(false);
  const [training, setTraining] = useState([]);
  const [location, setLocation] = useState(null);
  const [main, setMain] = useState(false);
  const [place, setPlace] = useState(null);

  const fetchData = async () => {
    const { data } = await dispatchAPI('GET', { url: '/training-places' });
    setTraining(data);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const body = { training_place: { distance: true } };
      if (!distance) {
        if (!main) {
          if (!place) {
            message.error('Veuillez selectionner un lieu de formation');
            return;
          }
          body.training_place = { training_place: place };
        } else {
          if (!location) {
            message.error('Veuillez entrer une adresse');
            return;
          }
          body.training_place = { location };
        }
      }
      await dispatchAPI('PATCH', {
        url: `/module/${module._id}`,
        body
      });
      message.success(`${purpose} du lieu de formation réussi`);
      setForceRefresh(!forceRefresh);
      setRefresh();
      setIsModalVisible(false);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setMain(module && module.training_place && module.training_place.location);
    setDistance(
      module && module.training_place && module.training_place.distance
    );
    setLocation(
      module && module.training_place && module.training_place.location
    );
    setPlace(
      module &&
        module.training_place &&
        module.training_place.training_place &&
        module.training_place.training_place._id
    );
  }, [module]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        title={t('sessions.module.place')}
        width={800}
      >
        <div
          style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}
        >
          <p style={{ marginRight: '10px' }}>En présentiel</p>
          <Switch
            onChange={(value) => setDistance(value)}
            defaultChecked={
              module && module.training_place && module.training_place.distance
            }
          />
          <p style={{ marginLeft: '10px' }}>En distanciel</p>
        </div>

        {!distance ? (
          <>
            <Divider />
            <div style={{ width: '50%', margin: 'auto' }}>
              {main ? (
                <Input
                  onChange={(e) => setLocation(e.target.value)}
                  defaultValue={
                    module &&
                    module.training_place &&
                    module.training_place.location
                  }
                />
              ) : (
                <Select
                  style={{ width: '100%' }}
                  onChange={(value) => setPlace(value)}
                  value={place && place.toString()}
                >
                  {training.map((t) => (
                    <Option key={t._id} value={t._id.toString()}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              )}
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'center'
                }}
              >
                <p style={{ marginRight: '10px' }}>Choisir un lieu existant</p>
                <Switch
                  onChange={(value) => setMain(value)}
                  defaultChecked={
                    module &&
                    module.training_place &&
                    module.training_place.location
                  }
                />
                <p style={{ marginLeft: '10px' }}>Entrer une autre adresse</p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

ModalSendMails.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  module: PropTypes.shape({
    _id: PropTypes.string,
    training_place: PropTypes.shape({
      distance: PropTypes.bool,
      location: PropTypes.string,
      training_place: PropTypes.shape({
        _id: PropTypes.string
      })
    })
  }),
  purpose: PropTypes.string,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  setRefresh: PropTypes.func
};

ModalSendMails.defaultProps = {
  isModalVisible: false,
  setIsModalVisible: () => {},
  module: {},
  purpose: '',
  forceRefresh: false,
  setForceRefresh: () => {},
  setRefresh: () => {}
};

export default ModalSendMails;
