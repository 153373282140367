import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { Col, Row, Spin, Statistic } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const Quote = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataWaiting, setDataWaiting] = useState([]);
  const [montant, setMontant] = useState(0);
  const [nbDevisWaiting, setNbDevisWaiting] = useState(0);
  const [sessionData, setSessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataSessions = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions?organization=${user.organization._id}&fields=files,files.date,files.amount,files.status,files.type&isQuote=true`
      });
      setSessionData(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const getSessions = () => {
    const res = sessionData.flatMap((el) =>
      el.files.map((file) => ({
        ...file,
        session: el._id,
        month: moment(file.date).format('MM'),
        type: moment(file.date).format('YYYY')
      }))
    );
    processDevis(res);
  };

  const processDevis = (devis) => {
    const year = [];
    let newDataWaiting = [];
    let nombreWaiting = 0;
    let amount = 0;

    devis.forEach((dev) => {
      if (!year.includes(dev.type)) {
        year.push(dev.type);
        newDataWaiting = [...newDataWaiting, ...getDefaultData(dev.type)];
      }
    });
    devis.forEach((el) => {
      newDataWaiting.forEach((nd, index) => {
        if (
          nd.type === el.type &&
          (nd.month === el.month || `0${nd.month}` === el.month)
        ) {
          if (el.status === 'waiting') {
            nombreWaiting++;
            amount += el.amount || 0;
            for (let i = index; i < newDataWaiting.length; i++) {
              newDataWaiting[i].value += el.amount || 0;
            }
          }
        }
      });
    });

    setMontant(amount);
    setNbDevisWaiting(nombreWaiting);
    setDataWaiting(newDataWaiting);
  };

  const getDefaultData = (year) => {
    return Array.from({ length: 12 }, (_, i) => ({
      month: `${i + 1}`,
      type: year,
      value: 0
    }));
  };

  useEffect(() => {
    if (sessionData.length > 0) {
      getSessions();
    }
  }, [sessionData]);

  useEffect(() => {
    fetchDataSessions();
  }, []);

  const config = {
    xField: 'month',
    yField: 'value',
    seriesField: 'type',
    color: ({ type }) =>
      type === '2021' ? '#F76B00' : type === '2020' ? '#4F71A8' : '#2b2b2b',
    lineStyle: ({ type }) =>
      type === '2020' ? { lineDash: [4, 4], opacity: 1 } : { opacity: 0.5 },
    height: 340
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={12}>
          <Statistic
            title="Nombre de devis en attente"
            value={nbDevisWaiting}
          />
        </Col>
        <Col span={12}>
          <Statistic title="Montant potentiel" suffix="€" value={montant} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Line {...{ ...config, data: dataWaiting }} />
        </Col>
      </Row>
    </Spin>
  );
};

Quote.propTypes = {
  sessionData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Quote;
