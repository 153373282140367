import React from 'react';
import moment from 'moment';
import { CheckOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that provides a list of actions based on the session, customerId, contacts, and emails.
 * @hook
 * @param {Object} session - The session object.
 * @param {function} setCustomerId - The function to set the customerId.
 * @param {Array} contacts - The array of contacts.
 * @param {Array} emails - The array of emails.
 * @returns {Object} - An object containing the listActions function.
 */
export const useListActions = (session, setCustomerId, contacts, emails) => {
  const { t } = useTranslation();

  const listActions = (type, id, ref) => {
    setCustomerId(id);
    let actions = [];
    session.files.forEach((f) => {
      if (
        f.user &&
        f.user._id.toString() === id.toString() &&
        f.type === type
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined
                  style={{ margin: '4px 2px 0 0', fontSize: 14 }}
                />
                {`${t('sessions.conventions.button.generate_the')} ${moment(
                  f.date
                ).format('DD-MM-YYYY')} `}
              </span>
              <span>
                {`${t('sessions.conventions.button.format')} ${
                  f.filename.split('.')[1]
                }`}
              </span>
            </Tag>
          </>
        ];
      }
    });

    if (ref === 'Company') {
      const contactsList = contacts
        .filter(
          (el) =>
            el.my_company._id.toString() === id.toString() &&
            el.company_representative
        )
        .map((el) => el._id);
      const emailsList = emails
        .filter((el) => el.user?._id)
        .filter((el) => {
          return el.user && contactsList.includes(el.user._id?.toString());
        });
      if (emailsList.length) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              Envoyée
            </span>
          </Tag>
        );
      }
    } else {
      const email = emails
        .filter((el) => el.user?._id)
        .find(
          (el) =>
            el.user &&
            el.user._id.toString() === id.toString() &&
            el.purpose === 'conventions'
        );
      if (email) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              {t('sessions.conventions.button.send')}
            </span>
          </Tag>
        );
      }
    }
    return actions;
  };

  return { listActions };
};
