import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Divider,
  message,
  Popconfirm,
  Row,
  Menu,
  Dropdown,
  Spin,
  Alert,
  Col
} from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components';
import useAuthContext from '../../../contexts/AuthContext';
import ModalSendMails from '../Convocations/modalSendMails';
import ModalUpdateNameQuiz from './ModalUpdateNameQuiz';
import DistributeAndShowResponsesRate from './DistributeAndShowResponsesRate';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';

const DetailQuiz = ({ purpose }) => {
  const [quiz, setQuiz] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleModalUpdate, setIsVisibleModalUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const { notification } = ErrorStatusCode();
  const { pathname } = useLocation();
  const history = useHistory();
  const urlSplit = pathname.split('/');
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const [traineesList, setTraineesList] = useState([]);
  const [percent, setPercent] = useState();
  const [emailModel, setEmailModel] = useState({});
  const [generateStatus, setGenerateStatus] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );

  const getFilesQuizzes = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'evaluations'
      );
      setTemplates(list);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${quiz.session}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          { path: 'funders', populate: { path: 'funder' } },
          { path: 'files', populate: { path: 'user' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}`
      });
      setCustomers(
        data.customers
          .filter((el) => el.status === 'ACCEPTED')
          .map(({ customer, ...c }) => ({
            ...c,
            customer,
            key: customer.id
          }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getTrainees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/trainees` });
      setTrainees(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getQuizzesResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quizzes_responses?quiz=${id}&populate=user`
      });
      setQuizzesResponses(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteQuizResponses = async (idResp) => {
    try {
      await dispatchAPI('DELETE', { url: `/quizzes_responses/${idResp}` });
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteAllResponses = async () => {
    quizzesResponses
      .filter((el) => el.quiz === id)
      .forEach(async (response) => {
        await deleteQuizResponses(response._id);
      });
    await getQuizzesResponses();
  };

  const deleteQuiz = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/quizzes/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const getQuiz = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/quizzes/${id}` });
      setQuiz(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    Promise.all([
      getQuiz(),
      getTrainees(),
      getFilesQuizzes(),
      getQuizzesResponses()
    ]);
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (Object.keys(quiz).length && quiz.session) {
        await getCustomers();
      }
    })();
  }, [quiz]);

  useEffect(() => {
    const list = [];
    customers.forEach((customer) => {
      if (customer.ref === 'Trainees') {
        list.push(customer.customer);
      } else {
        list.push(...customer.trainees_list);
      }
    });
    setTraineesList(list);
    setUsers(list);
  }, [customers, trainees]);

  useEffect(() => {
    setPercent(
      (quizzesResponses.filter((el) => el.quiz === id).length /
        traineesList.length) *
        100
    );
  }, [quizzesResponses]);

  const generateFile = async (templateId, fileType) => {
    try {
      message.info(
        'Veuillez patienter, le document est en cours de génération',
        5
      );
      setGenerateStatus(true);
      const response = await dispatchAPI('GET', {
        url: `/files/generate_evaluations/${id}/${urlSplit[3]}/${templateId}?fileType=${fileType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${quiz.name.split('.')[0]}.${fileType}`;
      a.target = '_blank';
      a.click();
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      setGenerateStatus(false);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const menuDropDown = () => {
    const menu = (
      <Menu>
        {templates.map((el, index) => (
          <>
            <Menu.Item key={`${el._id}-${index + 1}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  style={{ width: '100%', textAlign: 'left' }}
                  onClick={() => generateFile(el._id, 'docx')}
                >
                  {`${el.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key={`${el._id}-${index}`}>
              {generateStatus ? (
                <Button type="link" disabled>
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  style={{ width: '100%', textAlign: 'left' }}
                  type="link"
                  onClick={() => generateFile(el._id, 'pdf')}
                >
                  {`${el.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );
    return menu;
  };

  useEffect(() => {
    switch (quiz.type) {
      case 'pre_training':
        setEmailModel('pre_training_quiz_model');
        break;
      case 'training_quiz':
        setEmailModel('training_quiz_model');
        break;
      case 'post_training_j':
        setEmailModel('post_training_j_model_quiz_model');
        break;
      case 'post_training_month':
        setEmailModel('post_training_month_model_quiz_model');
        break;
      case 'managers_quizzes':
        setEmailModel('managers_quizzes_model');
        break;
      case 'contributors_quizzes':
        setEmailModel('contributors_quizzes_model');
        break;
      case 'funders_quizzes':
        setEmailModel('funders_quizzes_model');
        break;
      default:
        break;
    }
  }, [quiz]);

  return (
    <>
      <ModalGeneratingFile
        isVisible={isModalGenerateFileVisible}
        setVisible={setIsModalGenerateFileVisible}
      />
      <ModalUpdateNameQuiz
        title={quiz.name}
        setIsVisible={setIsVisibleModalUpdate}
        isVisible={isVisibleModalUpdate}
        refresh={refresh}
        setRefresh={setRefresh}
        purpose="edit"
      />
      <ModalSendMails
        setIsModalVisible={setIsVisible}
        isModalVisible={isVisible}
        users={users}
        file={[]}
        sessionId={quiz.session}
        refresh={refresh}
        setRefresh={setRefresh}
        templateId={35}
        emailPurpose="quizzes"
        emailModel={emailModel}
      />
      <PageHeaderCustom
        extra={
          <>
            <Button
              type="primary"
              onClick={() => setIsVisibleModalUpdate(!isVisibleModalUpdate)}
            >
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteQuiz}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
        title={quiz && quiz.name}
      />
      <Divider orientation="left">
        <span>{t(`Formulaire`)}</span>
      </Divider>
      {!quiz?.questions?.length && (
        <Alert
          style={{ margin: 16 }}
          message="Les stagiaires n'ont pas accès à ce quiz, veuillez y ajouter des questions"
          type="info"
          showIcon
        />
      )}
      <Card>
        {quizzesResponses.filter((el) => el.quiz === id).length !== 0 && (
          <>
            <Row>
              <span style={{ color: 'red' }}>
                Il y a déjà des réponses de stagiaires, impossible de modifier
                le formulaire.
              </span>
            </Row>
            <Row>
              <Button type="text" danger onClick={() => deleteAllResponses()}>
                <span style={{ fontSize: 12 }}>
                  Supprimer les réponses pour pouvoir modifier le formulaire
                </span>
              </Button>
            </Row>
          </>
        )}
        <Button
          disabled={quizzesResponses.filter((el) => el.quiz === id).length}
          type="link"
          onClick={() =>
            history.push(
              `/sessions/show/${urlSplit[3]}/quizzes/edit/${quiz._id}`
            )
          }
        >
          Modifier le formulaire
        </Button>
      </Card>
      {/* {purpose !== 'catalog' &&
        (quiz.type === 'training_quiz_model' ||
          quiz.type === 'training_quiz') && (
          <Space>
            <Checkbox />
            <span>
              Montrer le score et la correction au stagiaire après sa réponse
            </span>
          </Space>
        )} */}
      <Divider orientation="left">
        <span>{t(`Télécharger le formulaire`)}</span>
      </Divider>
      <Card>
        <Row>
          <Col>
            {generateStatus && <Spin />}
            <Dropdown
              disabled={!quiz?.questions?.length}
              overlay={() => menuDropDown()}
              trigger={['click']}
            >
              <Button type="link">
                Évaluation <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col>
            {!quiz?.questions?.length && (
              <Alert
                message="la liste des questions est vide"
                type="warning"
                showIcon
              />
            )}
          </Col>
        </Row>
      </Card>
      {purpose !== 'catalog' && (
        <>
          <DistributeAndShowResponsesRate
            quiz={quiz}
            traineesList={traineesList}
            percent={percent}
            quizzesResponses={quizzesResponses.filter((el) => el.quiz === id)}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            users={users}
            setUsers={setUsers}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </>
      )}
    </>
  );
};

export default DetailQuiz;
