import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      title: t('trainees.form.first_name'),
      dataIndex: 'first_name'
    },
    {
      title: t('trainees.form.last_name'),
      dataIndex: 'last_name'
    },
    {
      title: t('trainees.form.email'),
      dataIndex: 'email'
    },
    {
      title: t('trainees.form.gender'),
      dataIndex: 'gender',
      render: (gender) => t(`trainees.form.genders_enums.${gender}`)
    },
    {
      title: t('trainees.form.status'),
      dataIndex: 'status',
      render: (status) =>
        status ? t(`trainees.form.status_enums.${status}`) : ''
    },
    {
      title: t('trainees.form.company'),
      dataIndex: 'company.name'
    },
    {
      title: t('trainees.form.function'),
      dataIndex: 'function'
    },
    {
      title: t('trainees.form.study_level'),
      dataIndex: 'study_level'
    },
    {
      title: t('trainees.form.study_name'),
      dataIndex: 'study_name'
    },
    {
      title: t('trainees.form.number'),
      dataIndex: 'number'
    },
    {
      title: t('trainees.form.street'),
      dataIndex: 'street'
    },
    {
      title: t('trainees.form.additional'),
      dataIndex: 'additional'
    },
    {
      title: t('trainees.form.postcode'),
      dataIndex: 'postcode'
    },
    {
      title: t('trainees.form.city'),
      dataIndex: 'city'
    },
    {
      title: t('trainees.form.organization'),
      dataIndex: 'organization.name'
    }
  ];

  const columns = [
    {
      title: t('trainees.form.trainees.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('trainees.form.trainees.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('trainees.form.trainees.form.nb_sessions'),
      dataIndex: 'nb_sessions',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
