import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Option } = Select;

const useFields = () => {
  const { notification } = ErrorStatusCode();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [status, setStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, '($1) $2-$3');
  };
  const fields = [
    {
      label: 'gender',
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'number',
      name: ['number'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'street',
      rules: [{ required: true }],
      name: ['street']
    },
    {
      label: 'additional',
      name: ['additional']
    },
    {
      label: 'postcode',
      rules: [{ required: true }],
      name: ['postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'city',
      rules: [{ required: true }],
      name: ['city']
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[
              {
                required: true,
                message: t('contributors.form.phone_number.place_holder')
              }
            ]}
          >
            <Input
              pattern="[0-9]{9}"
              value={handlePhoneNumber(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '75%' }}
              title={t('errors_code.wrong_phone_number_format')}
            />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['company']
    },
    {
      name: ['siret_number']
    },
    {
      name: ['status'],
      input: (
        <Select
          onSelect={(value) => setStatus(value)}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.status || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contributors.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['rate'],
      input: <Input type="number" suffix="€" />,
      rules: [{ required: status === 'EXTERNAL' && true }]
    },
    {
      name: ['duration'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.duration || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contributors.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: status === 'EXTERNAL' && true }]
    },
    {
      name: ['tva'],
      input: <Input />,
      rules: [{ required: status === 'EXTERNAL' && true }]
    },
    {
      name: ['activity_declaration_number'],
      input: <Input type="number" />,
      rules: [
        {
          required: false,
          message: t('contacts.message.activity_declaration')
        }
      ]
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contributors/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
