import { Button } from 'antd';
import styled from 'styled-components';

/**
 * @Component
 * Custom styled components
 * Styled component for the column in the Kanban board.
 */
export const Col = styled.div`
  height: calc(100vh - 278px);
  position: relative;
  min-width: 250px;
  margin: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid rgba(235, 237, 242, 0.5);
`;

export const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  background-color: #2b2b2b;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 96%;
  text-align: left;
  color: #fff;
  height: 42px;
  #icon {
    padding: 0 20px;
  }
  #label {
    letter-spacing: 0.02em;
    font-weight: 500;
  }
`;
export const CardContainer = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 10px;
  background-color: transparent;
  color: white;
  width: 70%;
  border: 3px solid white;

  &:hover {
    background-color: transparent;
    color: white;
  }
`;
