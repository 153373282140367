export const initialState = {
  traineesDates: {
    isLoading: true,
    modules: [],
    customers: [],
    forceRefresh: false,
    session: null
  },
  progContribData: {
    isLoading: true,
    session: null,
    modules: {},
    emails: [],
    programs: [],
    forceRefresh: false
  }
};

export const sessionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TRAINING_DATES':
      return {
        ...state,
        traineesDates: action.payload
      };
    case 'SET_PROG_CONTRIB':
      return {
        ...state,
        progContribData: action.payload
      };
    default:
      return state;
  }
};
