import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Row, Col, Select, Button, message, Input, Alert } from 'antd';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';
import { myToolbar } from './utils/myToolbar';
import { fileTypesSelectValues } from './utils/fileTypesSelectValues';
import { setUniqueFiles } from './utils/setUniqueFiles';
import { getEmails } from './utils/getEmails';
import { setFiles } from './utils/setFiles';

const { Option } = Select;

const ModalSendMails = ({
  session,
  sessionId,
  emailModel,
  isModalVisible,
  setIsModalVisible,
  users,
  files,
  file,
  emailPurpose,
  refresh,
  setRefresh,
  globalEmail,
  setGlobalEmail,
  sendType,
  isSelectedDocument,
  emailContent,
  emailObject
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const { notification } = ErrorStatusCode();
  const [selectedIds, setSelectedIds] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [mailSubject, setMailSubject] = useState('');
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({});
  const [selectedModelEmail, setSelectedModelEmail] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesIds, setSelectedFilesIds] = useState([]);
  const [filtredFiles, setFiltredFiles] = useState([]);

  const warningMessage = (
    <div>
      <p>{t('emails.messages.dont_uptade')}</p>
      <p>{t('emails.messages.training_name')}</p>
      <p>{t('emails.messages.customer_name')}</p>
      <p>{t('emails.messages.url')}</p>
      <p>{t('emails.messages.organization_name')}</p>
    </div>
  );

  useEffect(() => {
    if (!isModalVisible) return;
    (async () => {
      await getEmails({
        dispatchAPI,
        emailModel,
        setEmails,
        notification,
        user
      });
    })();
  }, [emailModel, isModalVisible]);

  useEffect(() => {
    setFiles({
      globalEmail,
      setFiltredFiles,
      setSelectedFiles,
      file,
      setUniqueFiles,
      files,
      isSelectedDocument
    });
  }, [isSelectedDocument, file]);

  useEffect(() => {
    const filteredEmail = emails.filter(
      (el) => el.type === emailModel && el.default
    )[0];
    setSelectedModelEmail(filteredEmail?._id);
    setEmail(filteredEmail);
  }, [emails]);

  useEffect(() => {
    const list = [];
    users && users.map((user) => list.push(user._id));
    setSelectedIds(list);
  }, [users]);

  const createEmails = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/emails/many', body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const selectEmailModel = (key) => {
    setEmail(emails.filter((el) => el._id === key)[0]);
    setSelectedModelEmail(key);
  };

  useEffect(() => {
    if (email !== undefined && Object.keys(email).length && email.data) {
      email.subject && setMailSubject(email.subject);
      const html = email.data && convertFromHTML(email.data);
      const state =
        html &&
        ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);

      setEditorState(EditorState.createWithContent(state));
    }
    if (emailContent) {
      const html = convertFromHTML(emailContent);
      const state =
        html &&
        ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
      setEditorState(EditorState.createWithContent(state));
      setMailSubject(emailObject);
    }
  }, [email, emailContent]);

  const sendEmail = async () => {
    try {
      const emails = [];
      const params = JSON.stringify(selectedIds);
      const uniqueId = sessionId || id;
      const paramFiles = JSON.stringify(selectedFiles);
      const body = JSON.stringify(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
      const urlMail = `/email_sender/${sendType === 'multi' ? 'multi' : ''}`;

      await dispatchAPI('POST', {
        url: urlMail,
        body: {
          id: uniqueId,
          ids: params,
          files: paramFiles,
          body,
          subject: mailSubject,
          organizationId: user.organization._id
        }
      });

      (Array.isArray(selectedIds) ? selectedIds : [selectedIds]).forEach(
        (el) => {
          users.forEach((element) => {
            let newBody = body;
            let newSubject = email.subject;

            if (element._id === el) {
              newBody = body
                .replace('{{params.training_name}}', session?.name)
                .replace(
                  '{{params.customer_name}}',
                  `${element?.first_name || ''} ${element?.last_name || ''}`
                )
                .replace(
                  '{{params.organization_name}}',
                  `${user.organization.name}`
                )
                .replace('{{params.url}}', '###############')
                .replace(/"/g, '')
                .replace(/\\n/g, '<br />');

              newSubject = email.subject.replace(
                '{{params.training_name}}',
                session?.name
              );

              emails.push({
                type: email.type,
                name: email.name,
                subject: newSubject,
                session: session || id,
                user: el,
                purpose: emailPurpose,
                data: newBody,
                ref: element.ref,
                organization: user.organization._id
              });
            }
          });
        }
      );
      await createEmails(emails);
      setRefresh(!refresh);
      if (globalEmail) setGlobalEmail(false);
      setSelectedFilesIds([]);
      setSelectedIds([]);
      setIsModalVisible(false);
      message.success(t('emailConfirmation.send'));
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const handleCancel = () => {
    if (globalEmail) setGlobalEmail(false);
    setSelectedFilesIds([]);
    setSelectedIds([]);
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleChange = (value) => {
    setSelectedIds(value);
  };

  const selectSubject = (value) => {
    setMailSubject(value);
  };

  const selectFilesList = (value, all) => {
    setSelectedFilesIds(value);

    const newSelectedFiles = all.flatMap((item) =>
      filtredFiles.filter((el) => el.type === item.value)
    );

    setSelectedFiles(
      newSelectedFiles.map((item) => ({
        id: item._id,
        fileName: item.filename,
        user: item?.user?._id
      }))
    );
  };

  return (
    <>
      <Modal
        maskClosable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        width={800}
        footer={false}
      >
        <Row>
          <Col span={4}>
            <span>
              {globalEmail
                ? t('emails.form.recipients')
                : t('emails.form.recipient')}
            </span>
          </Col>
          <Col span={12}>
            <Select
              placeholder={t('emails.form.choose_recipient')}
              value={selectedIds}
              onChange={handleChange}
              mode={globalEmail ? 'multiple' : ''}
              style={{ width: '100%' }}
            >
              {users &&
                users.map((element) =>
                  element.ref === 'Contact' ? (
                    <Option value={element._id} key={element._id}>
                      {element.my_company.name}
                    </Option>
                  ) : (
                    <Option value={element._id} key={element._id}>
                      {`${element.first_name} ${element.last_name}`}
                    </Option>
                  )
                )}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col span={4}>{t('emails.form.email_model')}</Col>
          <Col span={12}>
            <Select
              placeholder={t('emails.form.choose_email_model')}
              value={selectedModelEmail}
              onSelect={selectEmailModel}
              style={{ width: '100%' }}
            >
              {emails &&
                emails.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {e.default ? (
                      <span>{`${e.name} ${t('emails.form.default')}`}</span>
                    ) : (
                      <span>{e.name}</span>
                    )}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        {isSelectedDocument && (
          <Row style={{ marginTop: 5 }}>
            <Col span={4}>{t('emails.form.documents')}</Col>
            <Col span={12}>
              <Select
                value={selectedFilesIds}
                placeholder={t('emails.form.choose_documents')}
                onChange={selectFilesList}
                mode="multiple"
                style={{ width: '100%' }}
              >
                {files &&
                  fileTypesSelectValues({ t }).map((f) => (
                    <Option value={f.value}>
                      <span>{f.label}</span>
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: 5 }}>
          <Col span={4}>
            <span>{t('emails.form.object')}</span>
          </Col>
          <Col span={12}>
            <Input
              value={mailSubject}
              onChange={(e) => selectSubject(e.target.value)}
            />
          </Col>
        </Row>
        {!emailContent ? (
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Alert message={warningMessage} type="info" showIcon />
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginTop: 5 }}>
          <div>
            <Editor
              toolbar={myToolbar}
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </div>
        </Row>
        {!emailContent ? (
          <Row>
            <Col offset={20} span={4}>
              <Button type="primary" onClick={sendEmail}>
                {t('buttons.send')}
              </Button>
            </Col>
          </Row>
        ) : null}
      </Modal>
    </>
  );
};

ModalSendMails.propTypes = {
  session: PropTypes.shape({
    program: PropTypes.shape({
      files: PropTypes.arrayOf(
        PropTypes.shape({ filename: PropTypes.string, _id: PropTypes.string })
      )
    }),
    name: PropTypes.string
  }),
  sessionId: PropTypes.string,
  emailModel: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  file: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emailPurpose: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  globalEmail: PropTypes.shape({}),
  setGlobalEmail: PropTypes.func,
  sendType: PropTypes.string.isRequired,
  isSelectedDocument: PropTypes.bool,
  emailContent: PropTypes.string,
  emailObject: PropTypes.string
};

ModalSendMails.defaultProps = {
  session: null,
  sessionId: null,
  globalEmail: null,
  setGlobalEmail: null,
  isSelectedDocument: false,
  emailContent: null,
  emailObject: null
};
export default ModalSendMails;
